import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M14.218 4.637a.999.999 0 0 1-.022 1.414l-3.728 3.615H24a1 1 0 0 1 0 2H10.468l3.728 3.615a1 1 0 1 1-1.392 1.436l-5.5-5.333a1 1 0 0 1 0-1.436l5.5-5.333a1 1 0 0 1 1.414.022zm3.564 10.667a1 1 0 0 1 1.414-.022l5.5 5.333a1 1 0 0 1 0 1.436l-5.5 5.333a.999.999 0 1 1-1.392-1.436l3.728-3.615H8a1 1 0 0 1 0-2h13.532l-3.728-3.615a1 1 0 0 1-.022-1.414z" />
    </Svg>
  )
}

Icon.displayName = 'SortHorizontal'

export const SortHorizontal = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
