import { gql } from '@apollo/client'
import { RecentPostFragment } from './fragments'

export const GetServices = gql`
  query GetServices($recentPostCount: Float = 5) {
    services {
      id
      name
      code
      enabled
      sort_order
      picture_id
    }

    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
