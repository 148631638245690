import * as React from "react";

type EventCallback = () => undefined | void | (() => void);

export const useFocusEffect = (callback: EventCallback) => {
  const isMounted = React.useRef(false)

  React.useEffect(() => {
    let remove: undefined | void | (() => void)
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        remove = callback()
      } else {
        remove?.()
      }
    };

    if (isMounted.current === false) {
      remove = callback()
      isMounted.current = true
    } else {
      isMounted.current = false
      remove?.()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [callback])
}