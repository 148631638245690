import * as React from "react"
import { useRouter } from "solito/router"
import { useFocusEffect } from "./useFocusEffect"

export const useNeedsUpdate = (needsUpdate: boolean) => {
  // if the apollo client throws update_needed
  // then this hooks takes user to update screen

  const router = useRouter()

  useFocusEffect(React.useCallback(() => {
    if (needsUpdate) {
      router.replace('/update')
    }
  }, [needsUpdate, router]))
}