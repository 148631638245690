import { styled } from 'tamagui'
import { SizableText } from './SizableText'

export const BodyText = styled(SizableText, {
  size: '$9',
  $gtXs: {
    size: '$10',
  },
  $gtMd: {
    size: '$12',
  },
})
