import '@/public/main.css'
import { tamagui } from '@my/ui'
import '@tamagui/core/reset.css'
import { ColorScheme, NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import { Footer } from 'galaxycard/components/Footer'
import { NavBar } from 'galaxycard/components/NavBar'
import { Provider } from 'galaxycard/provider'
import { initSprinkles } from 'galaxycard/provider/apollo-client/fraudDetection'
import { UserType } from 'galaxycard/types'
import { NextPage } from 'next'
import { Poppins, Work_Sans } from 'next/font/google'
import Head from 'next/head'
import 'raf/polyfill'
import * as React from 'react'
import type { SolitoAppProps } from 'solito'

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
  display: 'swap',
})

const workSans = Work_Sans({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
  display: 'swap',
})

const { YStack } = tamagui

if (process.env.NODE_ENV === 'production') {
  require('../public/tamagui.css')
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

function MyApp({
  Component,
  pageProps,
}: SolitoAppProps<{
  user: UserType | Partial<UserType> | null
}>) {
  // reference: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
  const getLayout = Component.getLayout || ((page) => page)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_theme, setTheme] = useRootTheme()

  // const router = useRouter()

  // React.useEffect(() => {
  //   // This pageview only triggers the first time (it's important for Pixel to have real information)
  //   analytics.logEvent('page_view', {
  //     page_location: window.location.href,
  //     page_referrer: document.referrer,
  //   })

  //   const handleRouteChange = () => {
  //     analytics.logScreenView({
  //       firebase_screen: router.pathname,
  //       firebase_screen_class: router.pathname,
  //     })
  //   }

  //   router.events.on('routeChangeComplete', handleRouteChange)
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange)
  //   }
  // }, [router.events, router.pathname])

  React.useEffect(() => {
    initSprinkles()
  }, [])

  return (
    <main className={poppins.className + ' ' + workSans.className}>
      <Head>
        <title>PaySay</title>
        <meta name="PaySay" content="PaySay" />
        <link rel="icon" href="/favicon.svg" />
      </Head>
      <NextThemeProvider
        onChangeTheme={(next) => {
          setTheme(next as ColorScheme)
        }}
      >
        <Provider user={pageProps.user} pageProps={pageProps}>
          <NavBar>
            <YStack gap="$10" $gtXs={{ gap: '$14' }} $gtMd={{ gap: '$17' }}>
              {getLayout(<Component {...pageProps} />)}
              <Footer />
            </YStack>
          </NavBar>
        </Provider>
      </NextThemeProvider>
    </main>
  )
}

export default MyApp
