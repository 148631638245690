import { gql } from '@apollo/client'

export const UserPersonal = gql`
  mutation documentPersonal($data: PersonalDataInput) {
    documentPersonal(data: $data) {
      status
      product
      status_data
      screen
    }
  }
`
