import { useFormikContext } from 'formik'
import * as React from 'react'
import { GestureResponderEvent } from 'react-native'
import { bugsnag } from '../utils/bugsnag'
import { analytics } from '../utils/firebase/analytics'

interface ValueProps {
  pin?: string
  confirmPin?: string
  [key: string]: unknown
}

export type AnalyticPressableProps = {
  code: string
  params?: { [key: string]: unknown }
}

type Props = AnalyticPressableProps & {
  onPress?: ((e: GestureResponderEvent) => unknown) | undefined | null
}

export default function useAnalyticsPressable({ onPress, code, params = {} }: Props) {
  const formik = useFormikContext<ValueProps>()

  const handlePress: (event: GestureResponderEvent) => unknown = React.useCallback(
    (e: GestureResponderEvent) => {
      const eventData: { [key: string]: unknown } = {
        target: code,
        ...params,
      }
      if (formik?.values !== undefined) {
        const formValues = {
          ...(formik?.values ?? {}),
        }
        delete formValues.pin
        delete formValues.confirmPin
        eventData.formValues = formValues
      }
      analytics.logEvent('click', eventData)
      bugsnag().then((client) => client.leaveBreadcrumb('Clicked', eventData, 'process'))

      onPress?.(e)
    },
    [code, formik?.values, onPress, params]
  )

  return {
    handlePress,
  }
}
