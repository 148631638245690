import { gql } from '@apollo/client'

export const addNewAddress = gql`
  mutation Address($data: AddressInput) {
    address(data: $data) {
      id
      firstname
      lastname
      address1
      address2
      city
      zipcode
      phone
      alternativePhone
      company
      state_id
      state
      country_id
      user_id
      label
      preferences
    }
  }
`

export const searchPinCode = gql`
  mutation Mutation($data: PincodeInput) {
    searchPincode(data: $data) {
      id
      pincode
      state_id
      city
    }
  }
`
