type Theme = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
  color8: string;
  color9: string;
  color10: string;
  color11: string;
  color12: string;
  background: string;
  backgroundHover: string;
  backgroundPress: string;
  backgroundFocus: string;
  backgroundStrong: string;
  backgroundTransparent: string;
  color: string;
  colorHover: string;
  colorPress: string;
  colorFocus: string;
  colorTransparent: string;
  borderColor: string;
  borderColorHover: string;
  borderColorFocus: string;
  borderColorPress: string;
  placeholderColor: string;
  blue1: string;
  blue2: string;
  blue3: string;
  blue4: string;
  blue5: string;
  blue6: string;
  blue7: string;
  blue8: string;
  blue9: string;
  blue10: string;
  redish1: string;
  redish2: string;
  redish3: string;
  redish4: string;
  redish5: string;
  redish6: string;
  redish7: string;
  redish8: string;
  redish9: string;
  redish10: string;
  olivGreen1: string;
  olivGreen2: string;
  olivGreen3: string;
  olivGreen4: string;
  olivGreen5: string;
  olivGreen6: string;
  olivGreen7: string;
  olivGreen8: string;
  olivGreen9: string;
  olivGreen10: string;
  orange1: string;
  orange2: string;
  orange3: string;
  orange4: string;
  orange5: string;
  orange6: string;
  orange7: string;
  orange8: string;
  orange9: string;
  orange10: string;
  red1: string;
  red2: string;
  red3: string;
  red4: string;
  red5: string;
  red6: string;
  red7: string;
  red8: string;
  red9: string;
  red10: string;
  green1: string;
  green2: string;
  green3: string;
  green4: string;
  green5: string;
  green6: string;
  green7: string;
  green8: string;
  green9: string;
  green10: string;
  gray1: string;
  gray2: string;
  gray3: string;
  gray4: string;
  gray5: string;
  gray6: string;
  gray7: string;
  gray8: string;
  gray9: string;
  gray10: string;
  aliceBlue1: string;
  aliceBlue2: string;
  aliceBlue3: string;
  aliceBlue4: string;
  aliceBlue5: string;
  aliceBlue6: string;
  aliceBlue7: string;
  aliceBlue8: string;
  aliceBlue9: string;
  aliceBlue10: string;
  shadowColor: string;
  shadowColorHover: string;
  shadowColorPress: string;
  shadowColorFocus: string;

}

function t(a) {
  let res: Record<string, string> = {}
  for (const [ki, vi] of a) {
    // @ts-ignore
    res[ks[ki]] = vs[vi]
  }
  return res
}
const vs = [
  'hsl(211, 31%, 95%)',
  'hsl(211, 20%, 86%)',
  'hsl(211, 21%, 76%)',
  'hsl(211, 21%, 66%)',
  'hsl(211, 21%, 56%)',
  'hsl(211, 21%, 46%)',
  'hsl(211, 32%, 27%)',
  'hsl(211, 32%, 22%)',
  'hsl(211, 33%, 16%)',
  'hsl(0, 0%, 100%)',
  'hsl(311, 31%, 95%)',
  'hsl(311, 20%, 86%)',
  'hsl(311, 21%, 76%)',
  'hsl(311, 21%, 66%)',
  'hsl(311, 21%, 56%)',
  'hsl(311, 21%, 46%)',
  'hsl(311, 32%, 27%)',
  'hsl(311, 32%, 22%)',
  'hsl(311, 33%, 16%)',
  'hsl(91, 31%, 95%)',
  'hsl(91, 20%, 86%)',
  'hsl(91, 21%, 76%)',
  'hsl(91, 21%, 66%)',
  'hsl(91, 21%, 56%)',
  'hsl(91, 21%, 46%)',
  'hsl(91, 32%, 27%)',
  'hsl(91, 32%, 22%)',
  'hsl(91, 33%, 16%)',
  'hsl(30, 100%, 96%)',
  'hsl(29, 100%, 92%)',
  'hsl(29, 100%, 85%)',
  'hsl(29, 100%, 79%)',
  'hsl(29, 100%, 69%)',
  'hsl(29, 97%, 57%)',
  'hsl(29, 97%, 54%)',
  'hsl(29, 99%, 46%)',
  'hsl(29, 100%, 41%)',
  'hsl(5, 100%, 98%)',
  'hsl(4, 97%, 94%)',
  'hsl(4, 100%, 88%)',
  'hsl(4, 100%, 80%)',
  'hsl(3, 100%, 72%)',
  'hsl(4, 100%, 65%)',
  'hsl(0, 100%, 61%)',
  'hsl(4, 83%, 50%)',
  'hsl(4, 88%, 44%)',
  'hsl(152, 100%, 97%)',
  'hsl(150, 100%, 92%)',
  'hsl(148, 65%, 73%)',
  'hsl(148, 67%, 64%)',
  'hsl(150, 64%, 50%)',
  'hsl(150, 72%, 41%)',
  'hsl(150, 82%, 36%)',
  'hsl(150, 88%, 32%)',
  'hsl(150, 94%, 27%)',
  '#FFFFFF',
  '#F4F4F4',
  '#E0E0E0',
  '#C4C4C4',
  '#A8A8A8',
  '#8C8C8C',
  '#707070',
  '#545454',
  '#434343',
  '#222222',
  'hsl(210, 100%, 95)',
  'hsl(210, 73%, 87%)',
  'hsl(210, 70%, 79%)',
  'hsl(210, 68%, 71%)',
  'hsl(210, 68%, 64%)',
  'hsl(210, 67%, 56%)',
  'hsl(210, 73%, 48%)',
  'hsl(210, 100%, 40%)',
  'hsl(209, 100%, 35%)',
  'rgba(0,0,0,0.066)',
  'rgba(0,0,0,0.02)',
  'rgba(0,0,0,0.3)',
  'rgba(0,0,0,0.2)',
  'rgba(0,0,0,0.5)',
  'rgba(0,0,0,0.9)',
  'undefined',
]

const ks = [
'color1',
'color2',
'color3',
'color4',
'color5',
'color6',
'color7',
'color8',
'color9',
'color10',
'color11',
'color12',
'background',
'backgroundHover',
'backgroundPress',
'backgroundFocus',
'backgroundStrong',
'backgroundTransparent',
'color',
'colorHover',
'colorPress',
'colorFocus',
'colorTransparent',
'borderColor',
'borderColorHover',
'borderColorFocus',
'borderColorPress',
'placeholderColor',
'blue1',
'blue2',
'blue3',
'blue4',
'blue5',
'blue6',
'blue7',
'blue8',
'blue9',
'blue10',
'redish1',
'redish2',
'redish3',
'redish4',
'redish5',
'redish6',
'redish7',
'redish8',
'redish9',
'redish10',
'olivGreen1',
'olivGreen2',
'olivGreen3',
'olivGreen4',
'olivGreen5',
'olivGreen6',
'olivGreen7',
'olivGreen8',
'olivGreen9',
'olivGreen10',
'orange1',
'orange2',
'orange3',
'orange4',
'orange5',
'orange6',
'orange7',
'orange8',
'orange9',
'orange10',
'red1',
'red2',
'red3',
'red4',
'red5',
'red6',
'red7',
'red8',
'red9',
'red10',
'green1',
'green2',
'green3',
'green4',
'green5',
'green6',
'green7',
'green8',
'green9',
'green10',
'gray1',
'gray2',
'gray3',
'gray4',
'gray5',
'gray6',
'gray7',
'gray8',
'gray9',
'gray10',
'aliceBlue1',
'aliceBlue2',
'aliceBlue3',
'aliceBlue4',
'aliceBlue5',
'aliceBlue6',
'aliceBlue7',
'aliceBlue8',
'aliceBlue9',
'aliceBlue10',
'shadowColor',
'shadowColorHover',
'shadowColorPress',
'shadowColorFocus']


const n1 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4],[28, 9],[29, 0],[30, 1],[31, 2],[32, 3],[33, 4],[34, 5],[35, 6],[36, 7],[37, 8],[38, 9],[39, 10],[40, 11],[41, 12],[42, 13],[43, 14],[44, 15],[45, 16],[46, 17],[47, 18],[48, 9],[49, 19],[50, 20],[51, 21],[52, 22],[53, 23],[54, 24],[55, 25],[56, 26],[57, 27],[58, 9],[59, 28],[60, 29],[61, 30],[62, 31],[63, 32],[64, 33],[65, 34],[66, 35],[67, 36],[68, 9],[69, 37],[70, 38],[71, 39],[72, 40],[73, 41],[74, 42],[75, 43],[76, 44],[77, 45],[78, 9],[79, 46],[80, 47],[81, 48],[82, 49],[83, 50],[84, 51],[85, 52],[86, 53],[87, 54],[88, 55],[89, 56],[90, 57],[91, 58],[92, 59],[93, 60],[94, 61],[95, 62],[96, 63],[97, 64],[98, 9],[99, 65],[100, 66],[101, 67],[102, 68],[103, 69],[104, 70],[105, 71],[106, 72],[107, 73],[108, 74],[109, 74],[110, 75],[111, 75]]) as Theme

export const light = n1 as Theme
const n2 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4],[28, 9],[29, 0],[30, 1],[31, 2],[32, 3],[33, 4],[34, 5],[35, 6],[36, 7],[37, 8],[38, 9],[39, 10],[40, 11],[41, 12],[42, 13],[43, 14],[44, 15],[45, 16],[46, 17],[47, 18],[48, 9],[49, 19],[50, 20],[51, 21],[52, 22],[53, 23],[54, 24],[55, 25],[56, 26],[57, 27],[58, 9],[59, 28],[60, 29],[61, 30],[62, 31],[63, 32],[64, 33],[65, 34],[66, 35],[67, 36],[68, 9],[69, 37],[70, 38],[71, 39],[72, 40],[73, 41],[74, 42],[75, 43],[76, 44],[77, 45],[78, 9],[79, 46],[80, 47],[81, 48],[82, 49],[83, 50],[84, 51],[85, 52],[86, 53],[87, 54],[88, 55],[89, 56],[90, 57],[91, 58],[92, 59],[93, 60],[94, 61],[95, 62],[96, 63],[97, 64],[98, 9],[99, 65],[100, 66],[101, 67],[102, 68],[103, 69],[104, 70],[105, 71],[106, 72],[107, 73],[108, 76],[109, 76],[110, 77],[111, 77]]) as Theme

export const dark = n2 as Theme
const n3 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 6],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 9],[19, 6],[20, 7],[21, 6],[22, 8],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4]]) as Theme

export const light_inverse = n3 as Theme
export const light_heroBoxGray = n3 as Theme
export const dark_inverse = n3 as Theme
export const dark_heroBoxGray = n3 as Theme
export const dark_inverse_ListItem = n3 as Theme
export const dark_heroBoxGray_ListItem = n3 as Theme
const n4 = t([[0, 56],[1, 57],[2, 58],[3, 59],[4, 60],[5, 61],[6, 62],[7, 63],[8, 64],[9, 64],[10, 64],[11, 64],[12, 55],[13, 58],[14, 59],[15, 60],[16, 56],[17, 55],[18, 64],[19, 62],[20, 63],[21, 62],[22, 64],[23, 59],[24, 61],[25, 59],[26, 60],[27, 60]]) as Theme

export const light_text = n4 as Theme
export const dark_text = n4 as Theme
export const dark_text_ListItem = n4 as Theme
const n5 = t([[0, 10],[1, 11],[2, 12],[3, 13],[4, 14],[5, 15],[6, 16],[7, 17],[8, 18],[9, 18],[10, 18],[11, 18],[12, 16],[13, 12],[14, 13],[15, 14],[16, 10],[17, 9],[18, 9],[19, 16],[20, 17],[21, 16],[22, 18],[23, 13],[24, 15],[25, 13],[26, 14],[27, 14]]) as Theme

export const light_heroBoxRedish = n5 as Theme
export const dark_heroBoxRedish = n5 as Theme
export const dark_heroBoxRedish_ListItem = n5 as Theme
const n6 = t([[0, 19],[1, 20],[2, 21],[3, 22],[4, 23],[5, 24],[6, 25],[7, 26],[8, 27],[9, 27],[10, 27],[11, 27],[12, 25],[13, 21],[14, 22],[15, 23],[16, 19],[17, 9],[18, 9],[19, 25],[20, 26],[21, 25],[22, 27],[23, 22],[24, 24],[25, 22],[26, 23],[27, 23]]) as Theme

export const light_heroBoxOliveGreen = n6 as Theme
export const dark_heroBoxOliveGreen = n6 as Theme
export const dark_heroBoxOliveGreen_ListItem = n6 as Theme
const n7 = t([[0, 37],[1, 38],[2, 39],[3, 40],[4, 41],[5, 42],[6, 43],[7, 44],[8, 45],[9, 45],[10, 45],[11, 45],[12, 9],[13, 39],[14, 40],[15, 41],[16, 37],[17, 9],[18, 45],[19, 43],[20, 44],[21, 43],[22, 45],[23, 40],[24, 42],[25, 40],[26, 41],[27, 41],[28, 9],[29, 0],[30, 1],[31, 2],[32, 3],[33, 4],[34, 5],[35, 6],[36, 7],[37, 8],[38, 9],[39, 10],[40, 11],[41, 12],[42, 13],[43, 14],[44, 15],[45, 16],[46, 17],[47, 18],[48, 9],[49, 19],[50, 20],[51, 21],[52, 22],[53, 23],[54, 24],[55, 25],[56, 26],[57, 27],[58, 9],[59, 28],[60, 29],[61, 30],[62, 31],[63, 32],[64, 33],[65, 34],[66, 35],[67, 36],[68, 9],[69, 37],[70, 38],[71, 39],[72, 40],[73, 41],[74, 42],[75, 43],[76, 44],[77, 45],[78, 9],[79, 46],[80, 47],[81, 48],[82, 49],[83, 50],[84, 51],[85, 52],[86, 53],[87, 54],[88, 55],[89, 56],[90, 57],[91, 58],[92, 59],[93, 60],[94, 61],[95, 62],[96, 63],[97, 64],[98, 9],[99, 65],[100, 66],[101, 67],[102, 68],[103, 69],[104, 70],[105, 71],[106, 72],[107, 73],[108, 76],[109, 76],[110, 77],[111, 77]]) as Theme

export const light_error = n7 as Theme
export const dark_error = n7 as Theme
const n8 = t([[0, 46],[1, 47],[2, 48],[3, 49],[4, 50],[5, 51],[6, 52],[7, 53],[8, 54],[9, 54],[10, 54],[11, 54],[12, 9],[13, 48],[14, 49],[15, 50],[16, 46],[17, 9],[18, 54],[19, 52],[20, 53],[21, 52],[22, 54],[23, 49],[24, 51],[25, 49],[26, 50],[27, 50],[28, 9],[29, 0],[30, 1],[31, 2],[32, 3],[33, 4],[34, 5],[35, 6],[36, 7],[37, 8],[38, 9],[39, 10],[40, 11],[41, 12],[42, 13],[43, 14],[44, 15],[45, 16],[46, 17],[47, 18],[48, 9],[49, 19],[50, 20],[51, 21],[52, 22],[53, 23],[54, 24],[55, 25],[56, 26],[57, 27],[58, 9],[59, 28],[60, 29],[61, 30],[62, 31],[63, 32],[64, 33],[65, 34],[66, 35],[67, 36],[68, 9],[69, 37],[70, 38],[71, 39],[72, 40],[73, 41],[74, 42],[75, 43],[76, 44],[77, 45],[78, 9],[79, 46],[80, 47],[81, 48],[82, 49],[83, 50],[84, 51],[85, 52],[86, 53],[87, 54],[88, 55],[89, 56],[90, 57],[91, 58],[92, 59],[93, 60],[94, 61],[95, 62],[96, 63],[97, 64],[98, 9],[99, 65],[100, 66],[101, 67],[102, 68],[103, 69],[104, 70],[105, 71],[106, 72],[107, 73],[108, 76],[109, 76],[110, 77],[111, 77]]) as Theme

export const light_success = n8 as Theme
export const dark_success = n8 as Theme
const n9 = t([[12, 78]]) as Theme

export const light_SheetOverlay = n9 as Theme
export const light_DialogOverlay = n9 as Theme
export const light_ModalOverlay = n9 as Theme
export const light_inverse_SheetOverlay = n9 as Theme
export const light_inverse_DialogOverlay = n9 as Theme
export const light_inverse_ModalOverlay = n9 as Theme
export const light_text_SheetOverlay = n9 as Theme
export const light_text_DialogOverlay = n9 as Theme
export const light_text_ModalOverlay = n9 as Theme
export const light_heroBoxGray_SheetOverlay = n9 as Theme
export const light_heroBoxGray_DialogOverlay = n9 as Theme
export const light_heroBoxGray_ModalOverlay = n9 as Theme
export const light_heroBoxRedish_SheetOverlay = n9 as Theme
export const light_heroBoxRedish_DialogOverlay = n9 as Theme
export const light_heroBoxRedish_ModalOverlay = n9 as Theme
export const light_heroBoxOliveGreen_SheetOverlay = n9 as Theme
export const light_heroBoxOliveGreen_DialogOverlay = n9 as Theme
export const light_heroBoxOliveGreen_ModalOverlay = n9 as Theme
export const light_alt1_SheetOverlay = n9 as Theme
export const light_alt1_DialogOverlay = n9 as Theme
export const light_alt1_ModalOverlay = n9 as Theme
export const light_alt2_SheetOverlay = n9 as Theme
export const light_alt2_DialogOverlay = n9 as Theme
export const light_alt2_ModalOverlay = n9 as Theme
export const light_active_SheetOverlay = n9 as Theme
export const light_active_DialogOverlay = n9 as Theme
export const light_active_ModalOverlay = n9 as Theme
export const light_error_SheetOverlay = n9 as Theme
export const light_error_DialogOverlay = n9 as Theme
export const light_error_ModalOverlay = n9 as Theme
export const light_success_SheetOverlay = n9 as Theme
export const light_success_DialogOverlay = n9 as Theme
export const light_success_ModalOverlay = n9 as Theme
const n10 = t([[12, 79]]) as Theme

export const dark_SheetOverlay = n10 as Theme
export const dark_DialogOverlay = n10 as Theme
export const dark_ModalOverlay = n10 as Theme
export const dark_inverse_SheetOverlay = n10 as Theme
export const dark_inverse_DialogOverlay = n10 as Theme
export const dark_inverse_ModalOverlay = n10 as Theme
export const dark_text_SheetOverlay = n10 as Theme
export const dark_text_DialogOverlay = n10 as Theme
export const dark_text_ModalOverlay = n10 as Theme
export const dark_heroBoxGray_SheetOverlay = n10 as Theme
export const dark_heroBoxGray_DialogOverlay = n10 as Theme
export const dark_heroBoxGray_ModalOverlay = n10 as Theme
export const dark_heroBoxRedish_SheetOverlay = n10 as Theme
export const dark_heroBoxRedish_DialogOverlay = n10 as Theme
export const dark_heroBoxRedish_ModalOverlay = n10 as Theme
export const dark_heroBoxOliveGreen_SheetOverlay = n10 as Theme
export const dark_heroBoxOliveGreen_DialogOverlay = n10 as Theme
export const dark_heroBoxOliveGreen_ModalOverlay = n10 as Theme
export const dark_alt1_SheetOverlay = n10 as Theme
export const dark_alt1_DialogOverlay = n10 as Theme
export const dark_alt1_ModalOverlay = n10 as Theme
export const dark_alt2_SheetOverlay = n10 as Theme
export const dark_alt2_DialogOverlay = n10 as Theme
export const dark_alt2_ModalOverlay = n10 as Theme
export const dark_active_SheetOverlay = n10 as Theme
export const dark_active_DialogOverlay = n10 as Theme
export const dark_active_ModalOverlay = n10 as Theme
export const dark_error_SheetOverlay = n10 as Theme
export const dark_error_DialogOverlay = n10 as Theme
export const dark_error_ModalOverlay = n10 as Theme
export const dark_success_SheetOverlay = n10 as Theme
export const dark_success_DialogOverlay = n10 as Theme
export const dark_success_ModalOverlay = n10 as Theme
const n11 = t([[0, 1],[1, 2],[2, 3],[3, 4],[4, 5],[5, 6],[6, 7],[7, 8],[8, 8],[9, 8],[10, 8],[11, 8],[12, 0],[13, 3],[14, 4],[15, 5],[16, 1],[17, 0],[18, 8],[19, 5],[20, 6],[21, 5],[22, 7],[23, 4],[24, 6],[25, 4],[26, 5],[27, 3]]) as Theme

export const light_alt1 = n11 as Theme
export const light_alt2 = n11 as Theme
export const dark_alt1 = n11 as Theme
export const dark_alt2 = n11 as Theme
export const dark_alt1_ListItem = n11 as Theme
export const dark_alt2_ListItem = n11 as Theme
const n12 = t([[0, 3],[1, 4],[2, 5],[3, 6],[4, 7],[5, 8],[6, 8],[7, 8],[8, 8],[9, 8],[10, 8],[11, 8],[12, 2],[13, 5],[14, 6],[15, 7],[16, 3],[17, 2],[19, 3],[20, 4],[21, 3],[22, 5],[23, 6],[24, 8],[25, 6],[26, 7],[27, 1]]) as Theme

export const light_active = n12 as Theme
export const dark_active = n12 as Theme
export const dark_active_ListItem = n12 as Theme
const n13 = t([[12, 9],[13, 1],[14, 2],[15, 3],[16, 9],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 2],[24, 4],[25, 2],[26, 3],[27, 5]]) as Theme

export const light_ListItem = n13 as Theme
const n14 = t([[12, 0],[13, 3],[14, 4],[15, 5],[16, 1],[17, 0],[18, 8],[19, 6],[20, 7],[21, 6],[22, 7],[23, 4],[24, 6],[25, 4],[26, 5],[27, 3]]) as Theme

export const light_Card = n14 as Theme
export const light_Checkbox = n14 as Theme
export const light_Switch = n14 as Theme
export const light_TooltipContent = n14 as Theme
export const light_DrawerFrame = n14 as Theme
export const light_Progress = n14 as Theme
export const light_TooltipArrow = n14 as Theme
export const light_SliderTrack = n14 as Theme
export const dark_Card = n14 as Theme
export const dark_Checkbox = n14 as Theme
export const dark_Switch = n14 as Theme
export const dark_TooltipContent = n14 as Theme
export const dark_DrawerFrame = n14 as Theme
export const dark_Progress = n14 as Theme
export const dark_TooltipArrow = n14 as Theme
export const dark_SliderTrack = n14 as Theme
const n15 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 8],[13, 6],[14, 7],[15, 6],[16, 80],[17, 8],[18, 9],[19, 2],[20, 3],[21, 4],[22, 9],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4],[-1, 0]]) as Theme

export const light_Button = n15 as Theme
export const dark_Button = n15 as Theme
const n16 = t([[12, 8],[13, 7],[14, 6],[15, 5],[16, 8],[17, 8],[18, 9],[19, 1],[20, 0],[21, 1],[22, 9],[23, 6],[24, 4],[25, 6],[26, 5],[27, 1]]) as Theme

export const light_SwitchThumb = n16 as Theme
export const dark_SwitchThumb = n16 as Theme
const n17 = t([[12, 6],[13, 3],[14, 2],[15, 1],[16, 5],[17, 6],[18, 9],[19, 1],[20, 0],[21, 1],[22, 1],[23, 2],[24, 0],[25, 2],[26, 1],[27, 5]]) as Theme

export const light_SliderTrackActive = n17 as Theme
export const dark_SliderTrackActive = n17 as Theme
const n18 = t([[12, 8],[13, 5],[14, 4],[15, 3],[16, 7],[17, 8],[18, 9],[19, 1],[20, 0],[21, 1],[22, 9],[23, 4],[24, 2],[25, 4],[26, 3],[27, 3]]) as Theme

export const light_SliderThumb = n18 as Theme
export const light_Tooltip = n18 as Theme
export const light_ProgressIndicator = n18 as Theme
export const dark_SliderThumb = n18 as Theme
export const dark_Tooltip = n18 as Theme
export const dark_ProgressIndicator = n18 as Theme
const n19 = t([[12, 9],[13, 1],[14, 2],[15, 3],[16, 9],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 4],[24, 6],[25, 4],[26, 5],[27, 5]]) as Theme

export const light_Input = n19 as Theme
export const light_TextArea = n19 as Theme
const n20 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4]]) as Theme

export const dark_ListItem = n20 as Theme
const n21 = t([[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 6],[20, 7],[21, 6],[22, 8],[23, 4],[24, 6],[25, 4],[26, 5],[27, 4]]) as Theme

export const dark_Input = n21 as Theme
export const dark_TextArea = n21 as Theme
const n22 = t([[12, 5],[13, 1],[14, 2],[15, 3],[16, 9],[17, 9],[18, 9],[19, 6],[20, 7],[21, 6],[22, 8],[23, 2],[24, 4],[25, 2],[26, 3],[27, 5]]) as Theme

export const light_inverse_ListItem = n22 as Theme
export const light_heroBoxGray_ListItem = n22 as Theme
const n23 = t([[12, 7],[13, 3],[14, 4],[15, 5],[16, 1],[17, 0],[18, 9],[19, 6],[20, 7],[21, 6],[22, 7],[23, 4],[24, 6],[25, 4],[26, 5],[27, 3]]) as Theme

export const light_inverse_Card = n23 as Theme
export const light_inverse_Checkbox = n23 as Theme
export const light_inverse_Switch = n23 as Theme
export const light_inverse_TooltipContent = n23 as Theme
export const light_inverse_DrawerFrame = n23 as Theme
export const light_inverse_Progress = n23 as Theme
export const light_inverse_TooltipArrow = n23 as Theme
export const light_inverse_SliderTrack = n23 as Theme
export const light_heroBoxGray_Card = n23 as Theme
export const light_heroBoxGray_Checkbox = n23 as Theme
export const light_heroBoxGray_Switch = n23 as Theme
export const light_heroBoxGray_TooltipContent = n23 as Theme
export const light_heroBoxGray_DrawerFrame = n23 as Theme
export const light_heroBoxGray_Progress = n23 as Theme
export const light_heroBoxGray_TooltipArrow = n23 as Theme
export const light_heroBoxGray_SliderTrack = n23 as Theme
export const dark_inverse_Card = n23 as Theme
export const dark_inverse_Checkbox = n23 as Theme
export const dark_inverse_Switch = n23 as Theme
export const dark_inverse_TooltipContent = n23 as Theme
export const dark_inverse_DrawerFrame = n23 as Theme
export const dark_inverse_Progress = n23 as Theme
export const dark_inverse_TooltipArrow = n23 as Theme
export const dark_inverse_SliderTrack = n23 as Theme
export const dark_heroBoxGray_Card = n23 as Theme
export const dark_heroBoxGray_Checkbox = n23 as Theme
export const dark_heroBoxGray_Switch = n23 as Theme
export const dark_heroBoxGray_TooltipContent = n23 as Theme
export const dark_heroBoxGray_DrawerFrame = n23 as Theme
export const dark_heroBoxGray_Progress = n23 as Theme
export const dark_heroBoxGray_TooltipArrow = n23 as Theme
export const dark_heroBoxGray_SliderTrack = n23 as Theme
const n24 = t([[0, 0],[1, 1],[2, 2],[3, 3],[4, 4],[5, 5],[6, 6],[7, 7],[8, 8],[9, 8],[10, 8],[11, 8],[12, 9],[13, 6],[14, 7],[15, 6],[16, 80],[17, 8],[18, 6],[19, 2],[20, 3],[21, 4],[22, 9],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4],[-1, 0]]) as Theme

export const light_inverse_Button = n24 as Theme
export const light_heroBoxGray_Button = n24 as Theme
export const dark_inverse_Button = n24 as Theme
export const dark_heroBoxGray_Button = n24 as Theme
const n25 = t([[12, 3],[13, 7],[14, 6],[15, 5],[16, 8],[17, 8],[18, 9],[19, 1],[20, 0],[21, 1],[22, 9],[23, 6],[24, 4],[25, 6],[26, 5],[27, 1]]) as Theme

export const light_inverse_SwitchThumb = n25 as Theme
export const light_heroBoxGray_SwitchThumb = n25 as Theme
export const dark_inverse_SwitchThumb = n25 as Theme
export const dark_heroBoxGray_SwitchThumb = n25 as Theme
const n26 = t([[12, 9],[13, 3],[14, 2],[15, 1],[16, 5],[17, 6],[18, 9],[19, 1],[20, 0],[21, 1],[22, 1],[23, 2],[24, 0],[25, 2],[26, 1],[27, 5]]) as Theme

export const light_inverse_SliderTrackActive = n26 as Theme
export const light_heroBoxGray_SliderTrackActive = n26 as Theme
export const dark_inverse_SliderTrackActive = n26 as Theme
export const dark_heroBoxGray_SliderTrackActive = n26 as Theme
const n27 = t([[12, 1],[13, 5],[14, 4],[15, 3],[16, 7],[17, 8],[18, 8],[19, 1],[20, 0],[21, 1],[22, 9],[23, 4],[24, 2],[25, 4],[26, 3],[27, 3]]) as Theme

export const light_inverse_SliderThumb = n27 as Theme
export const light_inverse_Tooltip = n27 as Theme
export const light_inverse_ProgressIndicator = n27 as Theme
export const light_heroBoxGray_SliderThumb = n27 as Theme
export const light_heroBoxGray_Tooltip = n27 as Theme
export const light_heroBoxGray_ProgressIndicator = n27 as Theme
export const dark_inverse_SliderThumb = n27 as Theme
export const dark_inverse_Tooltip = n27 as Theme
export const dark_inverse_ProgressIndicator = n27 as Theme
export const dark_heroBoxGray_SliderThumb = n27 as Theme
export const dark_heroBoxGray_Tooltip = n27 as Theme
export const dark_heroBoxGray_ProgressIndicator = n27 as Theme
const n28 = t([[12, 5],[13, 1],[14, 2],[15, 3],[16, 9],[17, 9],[18, 9],[19, 6],[20, 7],[21, 6],[22, 8],[23, 4],[24, 6],[25, 4],[26, 5],[27, 5]]) as Theme

export const light_inverse_Input = n28 as Theme
export const light_inverse_TextArea = n28 as Theme
export const light_heroBoxGray_Input = n28 as Theme
export const light_heroBoxGray_TextArea = n28 as Theme
const n29 = t([[12, 55],[13, 57],[14, 58],[15, 59],[16, 55],[17, 55],[18, 64],[19, 62],[20, 63],[21, 62],[22, 64],[23, 58],[24, 60],[25, 58],[26, 59],[27, 61]]) as Theme

export const light_text_ListItem = n29 as Theme
const n30 = t([[12, 56],[13, 59],[14, 60],[15, 61],[16, 57],[17, 56],[18, 64],[19, 62],[20, 63],[21, 62],[22, 63],[23, 60],[24, 62],[25, 60],[26, 61],[27, 59]]) as Theme

export const light_text_Card = n30 as Theme
export const light_text_Checkbox = n30 as Theme
export const light_text_Switch = n30 as Theme
export const light_text_TooltipContent = n30 as Theme
export const light_text_DrawerFrame = n30 as Theme
export const light_text_Progress = n30 as Theme
export const light_text_TooltipArrow = n30 as Theme
export const light_text_SliderTrack = n30 as Theme
export const dark_text_Card = n30 as Theme
export const dark_text_Checkbox = n30 as Theme
export const dark_text_Switch = n30 as Theme
export const dark_text_TooltipContent = n30 as Theme
export const dark_text_DrawerFrame = n30 as Theme
export const dark_text_Progress = n30 as Theme
export const dark_text_TooltipArrow = n30 as Theme
export const dark_text_SliderTrack = n30 as Theme
const n31 = t([[0, 56],[1, 57],[2, 58],[3, 59],[4, 60],[5, 61],[6, 62],[7, 63],[8, 64],[9, 64],[10, 64],[11, 64],[12, 64],[13, 62],[14, 63],[15, 62],[16, 80],[17, 64],[18, 55],[19, 58],[20, 59],[21, 60],[22, 55],[23, 59],[24, 61],[25, 59],[26, 60],[27, 60],[-1, 56]]) as Theme

export const light_text_Button = n31 as Theme
export const dark_text_Button = n31 as Theme
const n32 = t([[12, 64],[13, 63],[14, 62],[15, 61],[16, 64],[17, 64],[18, 55],[19, 57],[20, 56],[21, 57],[22, 55],[23, 62],[24, 60],[25, 62],[26, 61],[27, 57]]) as Theme

export const light_text_SwitchThumb = n32 as Theme
export const dark_text_SwitchThumb = n32 as Theme
const n33 = t([[12, 62],[13, 59],[14, 58],[15, 57],[16, 61],[17, 62],[18, 55],[19, 57],[20, 56],[21, 57],[22, 57],[23, 58],[24, 56],[25, 58],[26, 57],[27, 61]]) as Theme

export const light_text_SliderTrackActive = n33 as Theme
export const dark_text_SliderTrackActive = n33 as Theme
const n34 = t([[12, 64],[13, 61],[14, 60],[15, 59],[16, 63],[17, 64],[18, 55],[19, 57],[20, 56],[21, 57],[22, 55],[23, 60],[24, 58],[25, 60],[26, 59],[27, 59]]) as Theme

export const light_text_SliderThumb = n34 as Theme
export const light_text_Tooltip = n34 as Theme
export const light_text_ProgressIndicator = n34 as Theme
export const dark_text_SliderThumb = n34 as Theme
export const dark_text_Tooltip = n34 as Theme
export const dark_text_ProgressIndicator = n34 as Theme
const n35 = t([[12, 55],[13, 57],[14, 58],[15, 59],[16, 55],[17, 55],[18, 64],[19, 62],[20, 63],[21, 62],[22, 64],[23, 60],[24, 62],[25, 60],[26, 61],[27, 61]]) as Theme

export const light_text_Input = n35 as Theme
export const light_text_TextArea = n35 as Theme
const n36 = t([[12, 15],[13, 11],[14, 12],[15, 13],[16, 9],[17, 9],[18, 9],[19, 16],[20, 17],[21, 16],[22, 18],[23, 12],[24, 14],[25, 12],[26, 13],[27, 15]]) as Theme

export const light_heroBoxRedish_ListItem = n36 as Theme
const n37 = t([[12, 17],[13, 13],[14, 14],[15, 15],[16, 11],[17, 10],[18, 9],[19, 16],[20, 17],[21, 16],[22, 17],[23, 14],[24, 16],[25, 14],[26, 15],[27, 13]]) as Theme

export const light_heroBoxRedish_Card = n37 as Theme
export const light_heroBoxRedish_Checkbox = n37 as Theme
export const light_heroBoxRedish_Switch = n37 as Theme
export const light_heroBoxRedish_TooltipContent = n37 as Theme
export const light_heroBoxRedish_DrawerFrame = n37 as Theme
export const light_heroBoxRedish_Progress = n37 as Theme
export const light_heroBoxRedish_TooltipArrow = n37 as Theme
export const light_heroBoxRedish_SliderTrack = n37 as Theme
export const dark_heroBoxRedish_Card = n37 as Theme
export const dark_heroBoxRedish_Checkbox = n37 as Theme
export const dark_heroBoxRedish_Switch = n37 as Theme
export const dark_heroBoxRedish_TooltipContent = n37 as Theme
export const dark_heroBoxRedish_DrawerFrame = n37 as Theme
export const dark_heroBoxRedish_Progress = n37 as Theme
export const dark_heroBoxRedish_TooltipArrow = n37 as Theme
export const dark_heroBoxRedish_SliderTrack = n37 as Theme
const n38 = t([[0, 10],[1, 11],[2, 12],[3, 13],[4, 14],[5, 15],[6, 16],[7, 17],[8, 18],[9, 18],[10, 18],[11, 18],[12, 9],[13, 16],[14, 17],[15, 16],[16, 80],[17, 18],[18, 16],[19, 12],[20, 13],[21, 14],[22, 9],[23, 13],[24, 15],[25, 13],[26, 14],[27, 14],[-1, 10]]) as Theme

export const light_heroBoxRedish_Button = n38 as Theme
export const dark_heroBoxRedish_Button = n38 as Theme
const n39 = t([[12, 13],[13, 17],[14, 16],[15, 15],[16, 18],[17, 18],[18, 9],[19, 11],[20, 10],[21, 11],[22, 9],[23, 16],[24, 14],[25, 16],[26, 15],[27, 11]]) as Theme

export const light_heroBoxRedish_SwitchThumb = n39 as Theme
export const dark_heroBoxRedish_SwitchThumb = n39 as Theme
const n40 = t([[12, 9],[13, 13],[14, 12],[15, 11],[16, 15],[17, 16],[18, 9],[19, 11],[20, 10],[21, 11],[22, 11],[23, 12],[24, 10],[25, 12],[26, 11],[27, 15]]) as Theme

export const light_heroBoxRedish_SliderTrackActive = n40 as Theme
export const dark_heroBoxRedish_SliderTrackActive = n40 as Theme
const n41 = t([[12, 11],[13, 15],[14, 14],[15, 13],[16, 17],[17, 18],[18, 18],[19, 11],[20, 10],[21, 11],[22, 9],[23, 14],[24, 12],[25, 14],[26, 13],[27, 13]]) as Theme

export const light_heroBoxRedish_SliderThumb = n41 as Theme
export const light_heroBoxRedish_Tooltip = n41 as Theme
export const light_heroBoxRedish_ProgressIndicator = n41 as Theme
export const dark_heroBoxRedish_SliderThumb = n41 as Theme
export const dark_heroBoxRedish_Tooltip = n41 as Theme
export const dark_heroBoxRedish_ProgressIndicator = n41 as Theme
const n42 = t([[12, 15],[13, 11],[14, 12],[15, 13],[16, 9],[17, 9],[18, 9],[19, 16],[20, 17],[21, 16],[22, 18],[23, 14],[24, 16],[25, 14],[26, 15],[27, 15]]) as Theme

export const light_heroBoxRedish_Input = n42 as Theme
export const light_heroBoxRedish_TextArea = n42 as Theme
const n43 = t([[12, 24],[13, 20],[14, 21],[15, 22],[16, 9],[17, 9],[18, 9],[19, 25],[20, 26],[21, 25],[22, 27],[23, 21],[24, 23],[25, 21],[26, 22],[27, 24]]) as Theme

export const light_heroBoxOliveGreen_ListItem = n43 as Theme
const n44 = t([[12, 26],[13, 22],[14, 23],[15, 24],[16, 20],[17, 19],[18, 9],[19, 25],[20, 26],[21, 25],[22, 26],[23, 23],[24, 25],[25, 23],[26, 24],[27, 22]]) as Theme

export const light_heroBoxOliveGreen_Card = n44 as Theme
export const light_heroBoxOliveGreen_Checkbox = n44 as Theme
export const light_heroBoxOliveGreen_Switch = n44 as Theme
export const light_heroBoxOliveGreen_TooltipContent = n44 as Theme
export const light_heroBoxOliveGreen_DrawerFrame = n44 as Theme
export const light_heroBoxOliveGreen_Progress = n44 as Theme
export const light_heroBoxOliveGreen_TooltipArrow = n44 as Theme
export const light_heroBoxOliveGreen_SliderTrack = n44 as Theme
export const dark_heroBoxOliveGreen_Card = n44 as Theme
export const dark_heroBoxOliveGreen_Checkbox = n44 as Theme
export const dark_heroBoxOliveGreen_Switch = n44 as Theme
export const dark_heroBoxOliveGreen_TooltipContent = n44 as Theme
export const dark_heroBoxOliveGreen_DrawerFrame = n44 as Theme
export const dark_heroBoxOliveGreen_Progress = n44 as Theme
export const dark_heroBoxOliveGreen_TooltipArrow = n44 as Theme
export const dark_heroBoxOliveGreen_SliderTrack = n44 as Theme
const n45 = t([[0, 19],[1, 20],[2, 21],[3, 22],[4, 23],[5, 24],[6, 25],[7, 26],[8, 27],[9, 27],[10, 27],[11, 27],[12, 9],[13, 25],[14, 26],[15, 25],[16, 80],[17, 27],[18, 25],[19, 21],[20, 22],[21, 23],[22, 9],[23, 22],[24, 24],[25, 22],[26, 23],[27, 23],[-1, 19]]) as Theme

export const light_heroBoxOliveGreen_Button = n45 as Theme
export const dark_heroBoxOliveGreen_Button = n45 as Theme
const n46 = t([[12, 22],[13, 26],[14, 25],[15, 24],[16, 27],[17, 27],[18, 9],[19, 20],[20, 19],[21, 20],[22, 9],[23, 25],[24, 23],[25, 25],[26, 24],[27, 20]]) as Theme

export const light_heroBoxOliveGreen_SwitchThumb = n46 as Theme
export const dark_heroBoxOliveGreen_SwitchThumb = n46 as Theme
const n47 = t([[12, 9],[13, 22],[14, 21],[15, 20],[16, 24],[17, 25],[18, 9],[19, 20],[20, 19],[21, 20],[22, 20],[23, 21],[24, 19],[25, 21],[26, 20],[27, 24]]) as Theme

export const light_heroBoxOliveGreen_SliderTrackActive = n47 as Theme
export const dark_heroBoxOliveGreen_SliderTrackActive = n47 as Theme
const n48 = t([[12, 20],[13, 24],[14, 23],[15, 22],[16, 26],[17, 27],[18, 27],[19, 20],[20, 19],[21, 20],[22, 9],[23, 23],[24, 21],[25, 23],[26, 22],[27, 22]]) as Theme

export const light_heroBoxOliveGreen_SliderThumb = n48 as Theme
export const light_heroBoxOliveGreen_Tooltip = n48 as Theme
export const light_heroBoxOliveGreen_ProgressIndicator = n48 as Theme
export const dark_heroBoxOliveGreen_SliderThumb = n48 as Theme
export const dark_heroBoxOliveGreen_Tooltip = n48 as Theme
export const dark_heroBoxOliveGreen_ProgressIndicator = n48 as Theme
const n49 = t([[12, 24],[13, 20],[14, 21],[15, 22],[16, 9],[17, 9],[18, 9],[19, 25],[20, 26],[21, 25],[22, 27],[23, 23],[24, 25],[25, 23],[26, 24],[27, 24]]) as Theme

export const light_heroBoxOliveGreen_Input = n49 as Theme
export const light_heroBoxOliveGreen_TextArea = n49 as Theme
const n50 = t([[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 5],[20, 6],[21, 5],[22, 8],[23, 3],[24, 5],[25, 3],[26, 4],[27, 4]]) as Theme

export const light_alt1_ListItem = n50 as Theme
export const light_alt2_ListItem = n50 as Theme
const n51 = t([[12, 1],[13, 4],[14, 5],[15, 6],[16, 2],[17, 1],[18, 8],[19, 5],[20, 6],[21, 5],[22, 6],[23, 5],[24, 7],[25, 5],[26, 6],[27, 2]]) as Theme

export const light_alt1_Card = n51 as Theme
export const light_alt1_Checkbox = n51 as Theme
export const light_alt1_Switch = n51 as Theme
export const light_alt1_TooltipContent = n51 as Theme
export const light_alt1_DrawerFrame = n51 as Theme
export const light_alt1_Progress = n51 as Theme
export const light_alt1_TooltipArrow = n51 as Theme
export const light_alt1_SliderTrack = n51 as Theme
export const light_alt2_Card = n51 as Theme
export const light_alt2_Checkbox = n51 as Theme
export const light_alt2_Switch = n51 as Theme
export const light_alt2_TooltipContent = n51 as Theme
export const light_alt2_DrawerFrame = n51 as Theme
export const light_alt2_Progress = n51 as Theme
export const light_alt2_TooltipArrow = n51 as Theme
export const light_alt2_SliderTrack = n51 as Theme
export const dark_alt1_Card = n51 as Theme
export const dark_alt1_Checkbox = n51 as Theme
export const dark_alt1_Switch = n51 as Theme
export const dark_alt1_TooltipContent = n51 as Theme
export const dark_alt1_DrawerFrame = n51 as Theme
export const dark_alt1_Progress = n51 as Theme
export const dark_alt1_TooltipArrow = n51 as Theme
export const dark_alt1_SliderTrack = n51 as Theme
export const dark_alt2_Card = n51 as Theme
export const dark_alt2_Checkbox = n51 as Theme
export const dark_alt2_Switch = n51 as Theme
export const dark_alt2_TooltipContent = n51 as Theme
export const dark_alt2_DrawerFrame = n51 as Theme
export const dark_alt2_Progress = n51 as Theme
export const dark_alt2_TooltipArrow = n51 as Theme
export const dark_alt2_SliderTrack = n51 as Theme
const n52 = t([[0, 1],[1, 2],[2, 3],[3, 4],[4, 5],[5, 6],[6, 7],[7, 8],[8, 8],[9, 8],[10, 8],[11, 8],[12, 8],[13, 5],[14, 6],[15, 5],[16, 80],[17, 7],[18, 0],[19, 3],[20, 4],[21, 5],[22, 0],[23, 4],[24, 6],[25, 4],[26, 5],[27, 3],[-1, 1]]) as Theme

export const light_alt1_Button = n52 as Theme
export const light_alt2_Button = n52 as Theme
export const dark_alt1_Button = n52 as Theme
export const dark_alt2_Button = n52 as Theme
const n53 = t([[12, 8],[13, 6],[14, 5],[15, 4],[16, 8],[17, 8],[18, 9],[19, 2],[20, 1],[21, 2],[22, 9],[23, 5],[24, 3],[25, 5],[26, 4],[27, 2]]) as Theme

export const light_alt1_SwitchThumb = n53 as Theme
export const light_alt2_SwitchThumb = n53 as Theme
export const dark_alt1_SwitchThumb = n53 as Theme
export const dark_alt2_SwitchThumb = n53 as Theme
const n54 = t([[12, 5],[13, 2],[14, 1],[15, 0],[16, 4],[17, 5],[18, 9],[19, 2],[20, 1],[21, 2],[22, 2],[23, 1],[24, 9],[25, 1],[26, 0],[27, 6]]) as Theme

export const light_alt1_SliderTrackActive = n54 as Theme
export const light_alt2_SliderTrackActive = n54 as Theme
export const dark_alt1_SliderTrackActive = n54 as Theme
export const dark_alt2_SliderTrackActive = n54 as Theme
const n55 = t([[12, 7],[13, 4],[14, 3],[15, 2],[16, 6],[17, 7],[18, 9],[19, 2],[20, 1],[21, 2],[22, 0],[23, 3],[24, 1],[25, 3],[26, 2],[27, 4]]) as Theme

export const light_alt1_SliderThumb = n55 as Theme
export const light_alt1_Tooltip = n55 as Theme
export const light_alt1_ProgressIndicator = n55 as Theme
export const light_alt2_SliderThumb = n55 as Theme
export const light_alt2_Tooltip = n55 as Theme
export const light_alt2_ProgressIndicator = n55 as Theme
export const dark_alt1_SliderThumb = n55 as Theme
export const dark_alt1_Tooltip = n55 as Theme
export const dark_alt1_ProgressIndicator = n55 as Theme
export const dark_alt2_SliderThumb = n55 as Theme
export const dark_alt2_Tooltip = n55 as Theme
export const dark_alt2_ProgressIndicator = n55 as Theme
const n56 = t([[12, 9],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 8],[19, 5],[20, 6],[21, 5],[22, 8],[23, 5],[24, 7],[25, 5],[26, 6],[27, 4]]) as Theme

export const light_alt1_Input = n56 as Theme
export const light_alt1_TextArea = n56 as Theme
export const light_alt2_Input = n56 as Theme
export const light_alt2_TextArea = n56 as Theme
const n57 = t([[12, 1],[13, 4],[14, 5],[15, 6],[16, 2],[17, 1],[19, 3],[20, 4],[21, 3],[22, 6],[23, 5],[24, 7],[25, 5],[26, 6],[27, 2]]) as Theme

export const light_active_ListItem = n57 as Theme
const n58 = t([[12, 3],[13, 6],[14, 7],[15, 8],[16, 4],[17, 3],[19, 3],[20, 4],[21, 3],[22, 4],[23, 7],[24, 8],[25, 7],[26, 8],[27, 0]]) as Theme

export const light_active_Card = n58 as Theme
export const light_active_Checkbox = n58 as Theme
export const light_active_Switch = n58 as Theme
export const light_active_TooltipContent = n58 as Theme
export const light_active_DrawerFrame = n58 as Theme
export const light_active_Progress = n58 as Theme
export const light_active_TooltipArrow = n58 as Theme
export const light_active_SliderTrack = n58 as Theme
export const dark_active_Card = n58 as Theme
export const dark_active_Checkbox = n58 as Theme
export const dark_active_Switch = n58 as Theme
export const dark_active_TooltipContent = n58 as Theme
export const dark_active_DrawerFrame = n58 as Theme
export const dark_active_Progress = n58 as Theme
export const dark_active_TooltipArrow = n58 as Theme
export const dark_active_SliderTrack = n58 as Theme
const n59 = t([[0, 3],[1, 4],[2, 5],[3, 6],[4, 7],[5, 8],[6, 8],[7, 8],[8, 8],[9, 8],[10, 8],[11, 8],[12, 80],[13, 3],[14, 4],[15, 3],[16, 80],[17, 5],[19, 5],[20, 6],[21, 7],[22, 2],[23, 6],[24, 8],[25, 6],[26, 7],[27, 1],[18, 2],[-1, 3]]) as Theme

export const light_active_Button = n59 as Theme
export const dark_active_Button = n59 as Theme
const n60 = t([[12, 7],[13, 4],[14, 3],[15, 2],[16, 6],[17, 7],[19, 4],[20, 3],[21, 4],[22, 0],[23, 3],[24, 1],[25, 3],[26, 2],[27, 4]]) as Theme

export const light_active_SwitchThumb = n60 as Theme
export const dark_active_SwitchThumb = n60 as Theme
const n61 = t([[12, 3],[13, 0],[14, 9],[15, 9],[16, 2],[17, 3],[19, 4],[20, 3],[21, 4],[22, 4],[23, 9],[24, 9],[25, 9],[26, 9],[27, 8]]) as Theme

export const light_active_SliderTrackActive = n61 as Theme
export const dark_active_SliderTrackActive = n61 as Theme
const n62 = t([[12, 5],[13, 2],[14, 1],[15, 0],[16, 4],[17, 5],[19, 4],[20, 3],[21, 4],[22, 2],[23, 1],[24, 9],[25, 1],[26, 0],[27, 6]]) as Theme

export const light_active_SliderThumb = n62 as Theme
export const light_active_Tooltip = n62 as Theme
export const light_active_ProgressIndicator = n62 as Theme
export const dark_active_SliderThumb = n62 as Theme
export const dark_active_Tooltip = n62 as Theme
export const dark_active_ProgressIndicator = n62 as Theme
const n63 = t([[12, 1],[13, 4],[14, 5],[15, 6],[16, 2],[17, 1],[19, 3],[20, 4],[21, 3],[22, 6],[23, 7],[24, 8],[25, 7],[26, 8],[27, 2]]) as Theme

export const light_active_Input = n63 as Theme
export const light_active_TextArea = n63 as Theme
const n64 = t([[12, 9],[13, 38],[14, 39],[15, 40],[16, 9],[17, 9],[18, 45],[19, 43],[20, 44],[21, 43],[22, 45],[23, 39],[24, 41],[25, 39],[26, 40],[27, 42]]) as Theme

export const light_error_ListItem = n64 as Theme
const n65 = t([[12, 37],[13, 40],[14, 41],[15, 42],[16, 38],[17, 37],[18, 45],[19, 43],[20, 44],[21, 43],[22, 44],[23, 41],[24, 43],[25, 41],[26, 42],[27, 40]]) as Theme

export const light_error_Card = n65 as Theme
export const light_error_Checkbox = n65 as Theme
export const light_error_Switch = n65 as Theme
export const light_error_TooltipContent = n65 as Theme
export const light_error_DrawerFrame = n65 as Theme
export const light_error_Progress = n65 as Theme
export const light_error_TooltipArrow = n65 as Theme
export const light_error_SliderTrack = n65 as Theme
export const dark_error_Card = n65 as Theme
export const dark_error_Checkbox = n65 as Theme
export const dark_error_Switch = n65 as Theme
export const dark_error_TooltipContent = n65 as Theme
export const dark_error_DrawerFrame = n65 as Theme
export const dark_error_Progress = n65 as Theme
export const dark_error_TooltipArrow = n65 as Theme
export const dark_error_SliderTrack = n65 as Theme
const n66 = t([[0, 37],[1, 38],[2, 39],[3, 40],[4, 41],[5, 42],[6, 43],[7, 44],[8, 45],[9, 45],[10, 45],[11, 45],[12, 45],[13, 43],[14, 44],[15, 43],[16, 80],[17, 45],[18, 9],[19, 39],[20, 40],[21, 41],[22, 9],[23, 40],[24, 42],[25, 40],[26, 41],[27, 41],[-1, 37]]) as Theme

export const light_error_Button = n66 as Theme
export const dark_error_Button = n66 as Theme
const n67 = t([[12, 45],[13, 44],[14, 43],[15, 42],[16, 45],[17, 45],[18, 9],[19, 38],[20, 37],[21, 38],[22, 9],[23, 43],[24, 41],[25, 43],[26, 42],[27, 38]]) as Theme

export const light_error_SwitchThumb = n67 as Theme
export const dark_error_SwitchThumb = n67 as Theme
const n68 = t([[12, 43],[13, 40],[14, 39],[15, 38],[16, 42],[17, 43],[18, 9],[19, 38],[20, 37],[21, 38],[22, 38],[23, 39],[24, 37],[25, 39],[26, 38],[27, 42]]) as Theme

export const light_error_SliderTrackActive = n68 as Theme
export const dark_error_SliderTrackActive = n68 as Theme
const n69 = t([[12, 45],[13, 42],[14, 41],[15, 40],[16, 44],[17, 45],[18, 9],[19, 38],[20, 37],[21, 38],[22, 9],[23, 41],[24, 39],[25, 41],[26, 40],[27, 40]]) as Theme

export const light_error_SliderThumb = n69 as Theme
export const light_error_Tooltip = n69 as Theme
export const light_error_ProgressIndicator = n69 as Theme
export const dark_error_SliderThumb = n69 as Theme
export const dark_error_Tooltip = n69 as Theme
export const dark_error_ProgressIndicator = n69 as Theme
const n70 = t([[12, 9],[13, 38],[14, 39],[15, 40],[16, 9],[17, 9],[18, 45],[19, 43],[20, 44],[21, 43],[22, 45],[23, 41],[24, 43],[25, 41],[26, 42],[27, 42]]) as Theme

export const light_error_Input = n70 as Theme
export const light_error_TextArea = n70 as Theme
const n71 = t([[12, 9],[13, 47],[14, 48],[15, 49],[16, 9],[17, 9],[18, 54],[19, 52],[20, 53],[21, 52],[22, 54],[23, 48],[24, 50],[25, 48],[26, 49],[27, 51]]) as Theme

export const light_success_ListItem = n71 as Theme
const n72 = t([[12, 46],[13, 49],[14, 50],[15, 51],[16, 47],[17, 46],[18, 54],[19, 52],[20, 53],[21, 52],[22, 53],[23, 50],[24, 52],[25, 50],[26, 51],[27, 49]]) as Theme

export const light_success_Card = n72 as Theme
export const light_success_Checkbox = n72 as Theme
export const light_success_Switch = n72 as Theme
export const light_success_TooltipContent = n72 as Theme
export const light_success_DrawerFrame = n72 as Theme
export const light_success_Progress = n72 as Theme
export const light_success_TooltipArrow = n72 as Theme
export const light_success_SliderTrack = n72 as Theme
export const dark_success_Card = n72 as Theme
export const dark_success_Checkbox = n72 as Theme
export const dark_success_Switch = n72 as Theme
export const dark_success_TooltipContent = n72 as Theme
export const dark_success_DrawerFrame = n72 as Theme
export const dark_success_Progress = n72 as Theme
export const dark_success_TooltipArrow = n72 as Theme
export const dark_success_SliderTrack = n72 as Theme
const n73 = t([[0, 46],[1, 47],[2, 48],[3, 49],[4, 50],[5, 51],[6, 52],[7, 53],[8, 54],[9, 54],[10, 54],[11, 54],[12, 54],[13, 52],[14, 53],[15, 52],[16, 80],[17, 54],[18, 9],[19, 48],[20, 49],[21, 50],[22, 9],[23, 49],[24, 51],[25, 49],[26, 50],[27, 50],[-1, 46]]) as Theme

export const light_success_Button = n73 as Theme
export const dark_success_Button = n73 as Theme
const n74 = t([[12, 54],[13, 53],[14, 52],[15, 51],[16, 54],[17, 54],[18, 9],[19, 47],[20, 46],[21, 47],[22, 9],[23, 52],[24, 50],[25, 52],[26, 51],[27, 47]]) as Theme

export const light_success_SwitchThumb = n74 as Theme
export const dark_success_SwitchThumb = n74 as Theme
const n75 = t([[12, 52],[13, 49],[14, 48],[15, 47],[16, 51],[17, 52],[18, 9],[19, 47],[20, 46],[21, 47],[22, 47],[23, 48],[24, 46],[25, 48],[26, 47],[27, 51]]) as Theme

export const light_success_SliderTrackActive = n75 as Theme
export const dark_success_SliderTrackActive = n75 as Theme
const n76 = t([[12, 54],[13, 51],[14, 50],[15, 49],[16, 53],[17, 54],[18, 9],[19, 47],[20, 46],[21, 47],[22, 9],[23, 50],[24, 48],[25, 50],[26, 49],[27, 49]]) as Theme

export const light_success_SliderThumb = n76 as Theme
export const light_success_Tooltip = n76 as Theme
export const light_success_ProgressIndicator = n76 as Theme
export const dark_success_SliderThumb = n76 as Theme
export const dark_success_Tooltip = n76 as Theme
export const dark_success_ProgressIndicator = n76 as Theme
const n77 = t([[12, 9],[13, 47],[14, 48],[15, 49],[16, 9],[17, 9],[18, 54],[19, 52],[20, 53],[21, 52],[22, 54],[23, 50],[24, 52],[25, 50],[26, 51],[27, 51]]) as Theme

export const light_success_Input = n77 as Theme
export const light_success_TextArea = n77 as Theme
const n78 = t([[12, 6],[13, 2],[14, 3],[15, 4],[16, 0],[17, 9],[18, 9],[19, 6],[20, 7],[21, 6],[22, 8],[23, 4],[24, 6],[25, 4],[26, 5],[27, 4]]) as Theme

export const dark_inverse_Input = n78 as Theme
export const dark_inverse_TextArea = n78 as Theme
export const dark_heroBoxGray_Input = n78 as Theme
export const dark_heroBoxGray_TextArea = n78 as Theme
const n79 = t([[12, 55],[13, 58],[14, 59],[15, 60],[16, 56],[17, 55],[18, 64],[19, 62],[20, 63],[21, 62],[22, 64],[23, 60],[24, 62],[25, 60],[26, 61],[27, 60]]) as Theme

export const dark_text_Input = n79 as Theme
export const dark_text_TextArea = n79 as Theme
const n80 = t([[12, 16],[13, 12],[14, 13],[15, 14],[16, 10],[17, 9],[18, 9],[19, 16],[20, 17],[21, 16],[22, 18],[23, 14],[24, 16],[25, 14],[26, 15],[27, 14]]) as Theme

export const dark_heroBoxRedish_Input = n80 as Theme
export const dark_heroBoxRedish_TextArea = n80 as Theme
const n81 = t([[12, 25],[13, 21],[14, 22],[15, 23],[16, 19],[17, 9],[18, 9],[19, 25],[20, 26],[21, 25],[22, 27],[23, 23],[24, 25],[25, 23],[26, 24],[27, 23]]) as Theme

export const dark_heroBoxOliveGreen_Input = n81 as Theme
export const dark_heroBoxOliveGreen_TextArea = n81 as Theme
const n82 = t([[12, 0],[13, 3],[14, 4],[15, 5],[16, 1],[17, 0],[18, 8],[19, 5],[20, 6],[21, 5],[22, 7],[23, 5],[24, 7],[25, 5],[26, 6],[27, 3]]) as Theme

export const dark_alt1_Input = n82 as Theme
export const dark_alt1_TextArea = n82 as Theme
export const dark_alt2_Input = n82 as Theme
export const dark_alt2_TextArea = n82 as Theme
const n83 = t([[12, 2],[13, 5],[14, 6],[15, 7],[16, 3],[17, 2],[19, 3],[20, 4],[21, 3],[22, 5],[23, 7],[24, 8],[25, 7],[26, 8],[27, 1]]) as Theme

export const dark_active_Input = n83 as Theme
export const dark_active_TextArea = n83 as Theme
const n84 = t([[0, 37],[1, 38],[2, 39],[3, 40],[4, 41],[5, 42],[6, 43],[7, 44],[8, 45],[9, 45],[10, 45],[11, 45],[12, 9],[13, 39],[14, 40],[15, 41],[16, 37],[17, 9],[18, 45],[19, 43],[20, 44],[21, 43],[22, 45],[23, 40],[24, 42],[25, 40],[26, 41],[27, 41]]) as Theme

export const dark_error_ListItem = n84 as Theme
const n85 = t([[12, 9],[13, 39],[14, 40],[15, 41],[16, 37],[17, 9],[18, 45],[19, 43],[20, 44],[21, 43],[22, 45],[23, 41],[24, 43],[25, 41],[26, 42],[27, 41]]) as Theme

export const dark_error_Input = n85 as Theme
export const dark_error_TextArea = n85 as Theme
const n86 = t([[0, 46],[1, 47],[2, 48],[3, 49],[4, 50],[5, 51],[6, 52],[7, 53],[8, 54],[9, 54],[10, 54],[11, 54],[12, 9],[13, 48],[14, 49],[15, 50],[16, 46],[17, 9],[18, 54],[19, 52],[20, 53],[21, 52],[22, 54],[23, 49],[24, 51],[25, 49],[26, 50],[27, 50]]) as Theme

export const dark_success_ListItem = n86 as Theme
const n87 = t([[12, 9],[13, 48],[14, 49],[15, 50],[16, 46],[17, 9],[18, 54],[19, 52],[20, 53],[21, 52],[22, 54],[23, 50],[24, 52],[25, 50],[26, 51],[27, 50]]) as Theme

export const dark_success_Input = n87 as Theme
export const dark_success_TextArea = n87 as Theme