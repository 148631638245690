import { gql } from "@apollo/client";

export const BillingFragment = gql`
  fragment BillingFragment on Billing {
    balance
    bill
    limit
    endsAt
    lastPayBy
  }
`