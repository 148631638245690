import { gql } from '@apollo/client'

export const UserEmail = gql`
  mutation DocumentEmail($email: String!) {
    documentEmail(data: { email: $email }) {
      status
      product
      status_data
      screen
    }
  }
`
