import { AnalyticStack, BodyText, tamagui } from '@my/ui'
import * as React from 'react'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import { YStackProps } from 'tamagui'
import PaySayLogo from '../assets/logo.svg'
import { Hamburger, ShoppingBag, ShoppingCart, SortHorizontal } from '../icons'
import { NavTabs } from './NavTabs'
import { ProfilePhoto } from './ProfilePhoto'
import { ViewPort } from './Viewport'

const { ThemeableStack, YStack, XStack } = tamagui

export const NavBar = ({ children, ...props }: YStackProps) => {
  const [isDrawerVisible, toggleDrawer] = React.useReducer((s) => !s, false)

  return (
    <YStack {...props}>
      <ThemeableStack theme="inverse" backgroundColor="$background">
        <ViewPort
          f={1}
          px="$8"
          h="$6"
          $gtXs={{ px: '$10' }}
          $gtMd={{ px: '$20', h: '$8' }}
          ai="center"
          jc="space-between"
          fd="row"
        >
          <XStack gap="$6" jc="center">
            <AnalyticStack code="menu" onPress={toggleDrawer} $gtXs={{ dsp: 'none' }}>
              <Hamburger height={36} />
            </AnalyticStack>
            <Link href="/">
              <XStack $gtXs={{ scale: 1.11 }} $gtMd={{ scale: 1.33 }} jc="center">
                <SolitoImage src={PaySayLogo} alt="PaySay" height={36} />
              </XStack>
            </Link>
          </XStack>
          {/* {pathname === '/' && ( */}
          <XStack ai="center" gap="$18">
            <NavTabs orientation="horizontal" size="$4" dsp="none" $gtXs={{ dsp: 'flex' }} />
            <ProfilePhoto />
          </XStack>
          {/* )}
          {pathname !== '/' && (
            <XStack>
              <AnalyticStack code="back" onPress={handlePress}>
                <ChevronLeft />
              </AnalyticStack>
            </XStack>
          )} */}
        </ViewPort>
      </ThemeableStack>
      {children}
      {isDrawerVisible && (
        <XStack pos="absolute" t={0} b={0} l={0} r={0}>
          <YStack f={6} h="100%">
            <ThemeableStack
              theme="inverse"
              h="$6"
              backgroundColor="$background"
              px="$8"
              ai="center"
              jc="space-between"
              fd="row"
            >
              <XStack>
                <Link href="/">
                  <SolitoImage src={PaySayLogo} alt="PaySay" height={36} />
                </Link>
              </XStack>
            </ThemeableStack>
            <ThemeableStack theme="alt1" backgroundColor="$background" f={1} p="$8" gap="$8">
              <Link href="/shopping">
                <XStack fd="row" gap="$6" ai="center">
                  <ShoppingBag size={24} />
                  <BodyText>Shopping</BodyText>
                </XStack>
              </Link>
              <Link href="/transaction">
                <XStack gap="$6" ai="center">
                  <SortHorizontal size={24} />
                  <BodyText>Transaction History</BodyText>
                </XStack>
              </Link>
              <Link href="/cart">
                <XStack gap="$6" ai="center">
                  <ShoppingCart size={24} />
                  <BodyText>Cart</BodyText>
                </XStack>
              </Link>
            </ThemeableStack>
          </YStack>
          <AnalyticStack f={4} code="dismiss-drawer" onPress={toggleDrawer} bc="$shadowColor" />
        </XStack>
      )}
    </YStack>
  )
}
