import { gql } from '@apollo/client'

export const UserPan = gql`
  mutation documentPanCreate($data: PanInput) {
    documentPanCreate(data: $data) {
      status
      product
      status_data
      screen
      code
    }
  }
`
