// import { EventParams, getAnalytics, logEvent } from '@firebase/analytics'
// import app from './init.web'

declare global {
  interface Window {
    dataLayer: Array<unknown>
  }
}

let analytics

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
  // const firebaseAnalytics = getAnalytics(app)
  analytics = {
    logEvent: (
      event: string,
      params: {
        [key: string]: unknown
      }
    ) => window.dataLayer.push({ event, ...params }),
  }
}

export { analytics }
