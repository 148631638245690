import { SizeKeys, Sizes } from './token-size'

const spaces = Object.entries({
  $0: 0,
  '$0.5': 1,
  $1: 2,
  $2: 4,
  $3: 6,
  $4: 8,
  $5: 10,
  $6: 12,
  $7: 14,
  $8: 16,
  $9: 20,
  $true: 24,
  $10: 24,
  $11: 28,
  $12: 32,
  $13: 36,
  $14: 40,
  $15: 44,
  $16: 48,
  $17: 56,
  $18: 64,
  $19: 80,
  $20: 96,
  $21: 112,
  $22: 128,
  $23: 144,
  $24: 160,
  $25: 176,
  $26: 192,
  $27: 208,
  $28: 240,
}).map(([k, v]) => {
  return [k, v] as const
})

const spacesNegative = spaces.slice(1).map(([k, v]) => [`-${k.slice(1)}`, -v])

type SizeKeysWithNegatives =
  | Exclude<`-${SizeKeys extends `$${infer Key}` ? Key : SizeKeys}`, '-0'>
  | SizeKeys

export const space: {
  [Key in SizeKeysWithNegatives]: Key extends keyof Sizes ? Sizes[Key] : number
} = {
  ...Object.fromEntries(spaces),
  ...Object.fromEntries(spacesNegative),
}
