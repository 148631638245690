import { gql } from '@apollo/client'

export const UserAppStatus = gql`
  query Documents {
    documents {
      polling {
        status
        product
        status_data
        screen
        url
      }
    }
  }
`
