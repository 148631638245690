import { gql } from '@apollo/client'

export const updateQuantity = gql`
  mutation UpdateQuantity($data: UpdateQuantityInput) {
    updateQuantity(data: $data) {
      id
      state
      totalPrice
      discount
      finalPrice
      lineItems {
        id
        quantity
        product {
          id
          slug
          name
          vendor
          price
          originalPrice
          image
          options
        }
        price
        total
        name
      }
      address {
        id
        firstname
        lastname
        address1
        address2
        city
        zipcode
        phone
        alternativePhone
        company
        state_id
        state
        country_id
        user_id
        label
        preferences
      }
      promotions {
        id
        name
        coupon
        promoDiscount
        removable
      }
    }
  }
`
