import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const GetCategoriesList = gql`
  query categories($page: Int, $recentPostCount: Float = 5) {
    taxons(page: $page) {
      permalink
      name
      id
      icon{
        original_url
        alt
      }
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
