import { gql } from '@apollo/client'

export const RecentPostFragment = gql`
  fragment RecentPostFragment on Query {
    posts {
      recent(perPage: $recentPostCount) {
        name
        title
      }
    }
  }
`