import { Cookie } from 'tough-cookie'

export const cookieStorage = {
  get: (_key: string) => {},
  set: (cookie: Cookie) => {
    if (typeof window !== 'undefined') {
      document.cookie = `${cookie.cookieString()};domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`
    }
  },
  delete: (key: string) => {
    if (typeof window !== 'undefined') {
      document.cookie = `${key}=;max-age=0;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`
    }
  },
  deleteAll: () => {
    if (typeof window !== 'undefined') {
      const cookiesArray = document.cookie.split('; ')
      for (let i = 0; i < cookiesArray.length; i++) {
        document.cookie = `${cookiesArray[i].split('=')[0]}=;max-age=0;domain=${
          process.env.NEXT_PUBLIC_COOKIE_DOMAIN
        }`
      }
    }
  },
  getAllKeys: () => {
    if (typeof window !== 'undefined') {
      const cookiesArray = document.cookie.split('; ')
      const ans = []
      for (let i = 0; i < cookiesArray.length; i++) {
        ans.push(cookiesArray[i].split('=')[0])
      }
      return ans
    }
    return []
  },
  addOnValueChangedListener: (_listener: (key: string) => void) => ({
    remove: () => {},
  }),
}
