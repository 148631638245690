import { useMutation } from '@apollo/client'
import { UserLogout } from '@my/api'
import * as React from 'react'
import { Platform } from 'react-native'
import { UserType } from '../../types'
import { cookieJar } from '../../utils/cookieJar'
import { getBaseUrl } from '../../utils/getBaseUrl'
import { redirect } from '../../utils/redirect'

export interface UserDetailsContextType {
  user: UserType | Partial<UserType> | null
  setUser: React.Dispatch<React.SetStateAction<Partial<UserType> | UserType | null>>
}

export type UserDetailsProviderProps = {
  user: UserType | Partial<UserType> | null
  children?: React.ReactNode
}

export const UserDetailsContext = React.createContext<UserDetailsContextType | null>(null)

export const UserDetailsProvider = ({ user: initialUser, children }: UserDetailsProviderProps) => {
  const [user, setUser] = React.useState<UserType | Partial<UserType> | null>(initialUser)

  const lastUser = React.useRef<UserType | Partial<UserType> | null>()

  const [userLogout, { client }] = useMutation(UserLogout)

  React.useEffect(() => {
    if (user === null && lastUser.current !== null && lastUser.current !== undefined) {
      cookieJar.setCookie('authorization=', getBaseUrl())
      userLogout().finally(() => {
        client.resetStore()
        if (Platform.OS === 'web') {
          redirect('/')
        }
      })
    }
    lastUser.current = user
  }, [client, user, userLogout])

  React.useEffect(() => setUser(initialUser), [initialUser])

  return (
    <UserDetailsContext.Provider value={{ user, setUser }}>{children}</UserDetailsContext.Provider>
  )
}
