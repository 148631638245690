import * as React from 'react'
import { useNeedsUpdate } from '../../hooks/useNeedsUpdate'

export interface UpdateNowContextType {
  isUpdateNeeded: boolean
  setUpdateNeeded: React.Dispatch<React.SetStateAction<boolean>>
}

export const UpdateNowContext = React.createContext<UpdateNowContextType>(
  {} as UpdateNowContextType
)

export const UpdateNowProvider = ({ children }: { children?: React.ReactNode }) => {
  const [isUpdateNeeded, setUpdateNeeded] = React.useState(false)

  useNeedsUpdate(isUpdateNeeded)

  return (
    <UpdateNowContext.Provider value={{ isUpdateNeeded, setUpdateNeeded }}>
      {children}
    </UpdateNowContext.Provider>
  )
}
