import { isWeb, isWindowDefined } from '@tamagui/constants'
import { replaceLocalhost } from './getLocalhost'

export function _getBaseUrl() {
  if (isWeb || isWindowDefined) {
    return `https://${process.env.NEXT_PUBLIC_API_URL}`
  }

  if (process.env.EXPO_PUBLIC_API_URL) {
    // overwrites the rest - set this on your native app deployment
    return `https://${process.env.EXPO_PUBLIC_API_URL}`
  }

  if (process.env.VERCEL_URL) {
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`
  }

  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

export function getBaseUrl() {
  let url = _getBaseUrl()

  if (!isWeb) {
    url = replaceLocalhost(url)
  }
  return url
}
