import { gql } from '@apollo/client'
import { RecentPostFragment } from './fragments'

export const GetServiceByCode = gql`
  query GetServiceByCode($code: String, $recentPostCount: Float = 5) {
    service(serviceCode: $code) {
      id
      name
      enabled
      picture_id
      operators {
        id
        name
        category
        picture_id
      }
    }

    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
