import { gql } from '@apollo/client'
export const getAddresses = gql`
  query Query {
    me {
      addresses {
        address1
        address2
        alternativePhone
        city
        id
        firstname
        lastname
        zipcode
        phone
        company
        state_id
        state
      }
    }
    posts {
      recent(count: 5) {
        name
        title
      }
    }
  }
`
