import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const GetCategory = gql`
  query Categories($slug: String, $page: Float, $recentPostCount: Float = 5) {
    category(slug: $slug, page: $page) {
      products {
        id
        name
        price
        originalPrice
        image
        options
        master {
          id
          slug
          images
          originalPrice
          price
        }
      }
      name
      slug
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
