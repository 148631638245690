// this will run on page every server page load
// and set some cookies
import { baseSprinkles, listener } from './common'

export const initSprinkles = () => {
  if (typeof window === 'undefined') {
    return Promise.resolve()
  }

  return baseSprinkles()
}

export const sprinkles = () => {
  if (typeof window === 'undefined') {
    return
  }

  return listener()
}
