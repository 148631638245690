// this will run on page every server page load
// and set some cookies
import { EmitterSubscription, NativeEventEmitter, NativeModules, Platform } from 'react-native'
import {
  PowerState,
  getBaseOs,
  getFreeDiskStorage,
  getMaxMemory,
  getPowerState,
  getTotalDiskCapacity,
  getTotalMemory,
  isCameraPresent,
  isLocationEnabled,
} from 'react-native-device-info'
import { cookieJar } from '../../../utils/cookieJar'
import { getBaseUrl } from '../../../utils/getBaseUrl'

const deviceInfoEmitter = new NativeEventEmitter(NativeModules.RNDeviceInfo)

export const API_URL = getBaseUrl()

const handler = ({
  batteryLevel,
  lowPowerMode,
  batteryState,
  chargingtime,
  dischargingtime,
}: Partial<PowerState>) => {
  if (batteryLevel !== undefined) {
    cookieJar.setCookie(`battery-level=${batteryLevel}`, API_URL)
  }
  if (batteryState !== undefined) {
    cookieJar.setCookie(`battery-state=${batteryState}`, API_URL)
  }
  if (lowPowerMode !== undefined) {
    cookieJar.setCookie(`low-power-state=${lowPowerMode}`, API_URL)
  }
  if (chargingtime !== undefined) {
    cookieJar.setCookie(`charging-time=${chargingtime}`, API_URL)
  }
  if (dischargingtime !== undefined) {
    cookieJar.setCookie(`discharging-time=${dischargingtime}`, API_URL)
  }
}

export const listener = () => {
  const listeners: EmitterSubscription[] = []

  listeners.push(deviceInfoEmitter.addListener('RNDeviceInfo_powerStateDidChange', handler))
  listeners.push(deviceInfoEmitter.addListener('RNDeviceInfo_batteryLevelDidChange', handler))
  listeners.push(deviceInfoEmitter.addListener('RNDeviceInfo_batteryLevelIsLow', handler))

  return () => {
    listeners.forEach((listener) => listener.remove())
    listeners.splice(0)
  }
}

export const baseSprinkles = async () => {
  cookieJar.setCookie(`platform=${Platform.OS}`, API_URL)

  return Promise.all([
    getBaseOs().then((os) => cookieJar.setCookie(`os=${os}`, API_URL)),
    getMaxMemory().then((max) => cookieJar.setCookie(`max-memory=${max}`, API_URL)),
    isCameraPresent().then((present) => cookieJar.setCookie(`camera=${present}`, API_URL)),
    getTotalMemory().then((memory) => cookieJar.setCookie(`total-memory=${memory}`, API_URL)),
    getFreeDiskStorage().then((disk) => cookieJar.setCookie(`free-disk=${disk}`, API_URL)),
    getTotalDiskCapacity().then((capacity) =>
      cookieJar.setCookie(`total-disk-capacity=${capacity}`, API_URL)
    ),
    isLocationEnabled().then((enabled) =>
      cookieJar.setCookie(`location-enabled=${enabled}`, API_URL)
    ),
    getPowerState().then(handler),
  ])
}
