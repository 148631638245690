import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const GetBlogPost = gql`
  query GetBlogPost($name: String, $recentPostCount: Float = 5) {
    posts {
      storeName(name: $name) {
        name
        title
        content
      }
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
