import type {
  FirebaseMessagingTypes,
} from '@react-native-firebase/messaging';

const listeners: Array<
  (remoteMessage: FirebaseMessagingTypes.RemoteMessage, appState: 'foreground' | 'background') => unknown
> = [];

export const notificationHandler = (
  remoteMessage: FirebaseMessagingTypes.RemoteMessage,
  appState: 'foreground' | 'background'
) => {
  for (const listener of listeners) {
    listener(remoteMessage, appState);
  }
};

export const subscribe = function (
  fn: (remoteMessage: FirebaseMessagingTypes.RemoteMessage, appState: 'foreground' | 'background') => any,
): Function {
  listeners.push(fn);
  return () => {
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i] === fn) {
        listeners.splice(i, 1);
        return;
      }
    }
  };
};
