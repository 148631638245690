import { styled } from 'tamagui'
import { SizableText } from './SizableText'

const NAME = 'LabelText'

export const LabelText = styled(SizableText, {
  name: NAME,
  variants: {
    unstyled: {
      false: {
        size: '$9',
        $gtXs: {
          size: '$10',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})
