import { gql } from '@apollo/client'

export const UserAadhaar = gql`
  mutation DocumentAadhaarOtp($data: AadhaarOtpInput) {
    documentAadhaarOtp(data: $data) {
      sessionId
      code
    }
  }
`
