import { AnalyticStack, BodyText, tamagui } from '@my/ui'
import * as React from 'react'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import { useRouter } from 'solito/router'
import USER_AVATAR from '../assets/user.svg'
import { UserDetailsContext, UserDetailsContextType } from '../provider/auth'
import { UserType } from '../types'

const { Stack, Avatar, Popover, XStack, YStack } = tamagui

export function ProfilePhoto() {
  const { user, setUser } = React.useContext(UserDetailsContext) as UserDetailsContextType

  // const popoverRef = React.useRef<tamagui.TamaguiElement>(null)

  const router = useRouter()

  const logout = React.useCallback(() => setUser(null), [setUser])

  const handleLogin = React.useCallback(() => {
    router.push('/login')
    // TODO: find why the popover is not closing on menu item click
  }, [router])

  return (
    <>
      <Popover size={'$4'} allowFlip placement="bottom">
        <Popover.Trigger asChild>
          <XStack gap="$4" ai="center" dsp="none" $gtXs={{ dsp: 'flex' }}>
            <Avatar $gtXs={{ scale: 1.11 }} $gtMd={{ scale: 1.33 }}>
              <SolitoImage src={USER_AVATAR} alt="your avatar" height={32} />
            </Avatar>
            <BodyText>{`Hi, ${
              user === undefined || user === null ? 'Guest' : user.name ?? 'There'
            }`}</BodyText>
          </XStack>
        </Popover.Trigger>

        <Popover.Close>
          <Popover.Content
            br="$1"
            borderWidth={1}
            borderColor="$borderColor"
            enterStyle={{ y: -10, opacity: 0 }}
            exitStyle={{ y: -10, opacity: 0 }}
            elevate
            theme="inverse"
            animation={[
              'quick',
              {
                opacity: {
                  overshootClamping: true,
                },
              },
            ]}
          >
            <Popover.Arrow borderWidth={1} borderColor="$borderColor" />
            <YStack space="$2" width={'$12'}>
              {(user as UserType)?.user_id ? (
                <AnalyticStack code="logout" onPress={logout}>
                  <BodyText>Logout</BodyText>
                </AnalyticStack>
              ) : (
                <AnalyticStack code="login" onPress={handleLogin}>
                  <BodyText>Login</BodyText>
                </AnalyticStack>
              )}
            </YStack>
          </Popover.Content>
        </Popover.Close>
      </Popover>
      <Stack dsp="flex" $gtXs={{ dsp: 'none' }}>
        <Link href="/profile">
          <Avatar $gtXs={{ size: '$3' }} $gtMd={{ size: '$4' }} br="$7">
            <SolitoImage src={USER_AVATAR} alt="your avatar" fill />
          </Avatar>
        </Link>
      </Stack>
    </>
  )
}
