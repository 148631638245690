import { gql } from '@apollo/client'
import { BannersFragment, BillingFragment, RecentPostFragment } from './fragments'

export const FeaturedCategoriesFragment = gql`
  fragment FeaturedCategoriesFragment on Query {
    taxons(featured: true) {
      id
      name
      permalink
      products(per_page: $products_per_page) {
        id
        name
        image {
          original_url
          alt
        }
        primary_variant {
          id
          slug
          price
          compare_at_price
        }
      }
    }
  }
`

export const GetHomeData = gql`
  query GetHomeData(
    $withBilling: Boolean = false
    $withBanners: Boolean = false
    $recentPostCount: Float = 5
    $products_per_page: Int
  ) {
    ...BannersFragment @include(if: $withBanners)
    services {
      id
      name
      code
      enabled
      sort_order
      picture_id
    }
    me {
      billing @include(if: $withBilling) {
        ...BillingFragment
      }
    }
    ...FeaturedCategoriesFragment
    ...RecentPostFragment
  }
  ${FeaturedCategoriesFragment}
  ${BillingFragment}
  ${BannersFragment}
  ${RecentPostFragment}
`
