import { gql } from '@apollo/client'

export const getCoupons = gql`
  query Promotions {
    promotions {
      coupons {
        id
        description
        code
        amount
        percent
        expiry
      }
    }
  }
`
