import { gql } from '@apollo/client'
import { RecentPostFragment } from './fragments'
export const getTransactionStatus = gql`
  query Payment($uuid: String, $recentPostCount: Float = 5) {
    me {
      uuid(uuid: $uuid) {
        amount
        billable_at
        uuid
        category
        entity_id
        entity_type
        fee
        is_emi_disabled
        status
        shopping
        utility {
          number
          dispute
        }
        giftcard{
          number
          pin
          validity
        }
      }
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
