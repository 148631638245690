import * as React from 'react'
import { UserType } from '../types'
import { RecentPostCountProvider } from './RecentPostCountProvider'
import { ApolloClientProvider } from './apollo-client'
import { UserDetailsProvider } from './auth'
import { SafeAreaProvider } from './safe-area'
import { TamaguiProvider } from './tamagui'
import { UniversalThemeProvider } from './theme'
import { ToastProvider } from './toast'
import { UpdateNowProvider } from './update-now/UpdateNowProvider'
import { NotificationProvider } from './NotificationProvider'

export function Provider({
  pageProps = {},
  user,
  children,
}: {
  pageProps?: Record<string, unknown>
  user: UserType | Partial<UserType> | null
  children: React.ReactNode
}) {
  
  return (
    <UpdateNowProvider>
      <ApolloClientProvider pageProps={pageProps}>
        <UserDetailsProvider user={user}>
          <Providers>{children}</Providers>
        </UserDetailsProvider>
      </ApolloClientProvider>
    </UpdateNowProvider>
  )
}

const compose = (providers: React.FC<{ children: React.ReactNode }>[]) =>
  providers.reduce((Prev, Curr) => ({ children }) => {
    const Provider = Prev ? (
      <Prev>
        <Curr>{children}</Curr>
      </Prev>
    ) : (
      <Curr>{children}</Curr>
    )
    return Provider
  })

const Providers = compose([
  UniversalThemeProvider,
  SafeAreaProvider,
  TamaguiProvider,
  ToastProvider,
  RecentPostCountProvider,
  NotificationProvider,
])
