// we use this for getting the remote message
import { bugsnag } from '@my/ui/src/utils/bugsnag'
import type { FirebaseMessagingTypes } from '@react-native-firebase/messaging'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getMessaging, getToken as getTokenOG, onMessage } from 'firebase/messaging'
import { notificationHandler } from './common'

let messaging

export const init = (options: FirebaseOptions) => {
  const firebaseApp = initializeApp(options)
  messaging = getMessaging(firebaseApp)

  onMessage(messaging, (payload) => notificationHandler(payload as FirebaseMessagingTypes.RemoteMessage, 'foreground'))
}

export const hasPermission = async () => {
  return Notification.permission === 'granted'
}

export const getToken = async (listener: (token: string, reason: 'new' | 'refresh') => unknown): Promise<string | undefined> => {
  if (messaging === undefined) {
    throw new Error('Please call init first')
  }

  // we have to take user permission.......
  if (!hasPermission()) {
    await messaging.requestPermission()
  }
  let token
  try {
    token = await getTokenOG(messaging, {
      vapidKey:
        'BEuGNsNt3huovFzl5DCcCmBqBP-HsOZPryq42HRhsLFl480-pDFrfvdKbYU-oI0IXObmV6Dlw1W5XJxepEKdcmM',
    })
  } catch (e) {
    bugsnag().then((client) => client.notify(e))
  }
  if (token !== undefined) {
    listener(token, 'new')
  }
  return token;
}

export const deleteToken = async () => {
  messaging.deleteToken().catch((e) => bugsnag().then((client) => client.notify(e)))
  // todo: request on backend to delete the token.
}
