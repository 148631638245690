import { ThemeableStack, ThemeableStackProps } from 'tamagui'
import useAnalyticsPressable, { AnalyticPressableProps } from '../hooks/useAnalyticsPressable'

type Props = ThemeableStackProps & AnalyticPressableProps

export function AnalyticStack({ onPress, code, params, ...otherProps }: Props) {
  const { handlePress } = useAnalyticsPressable({ onPress, code, params })

  return <ThemeableStack {...otherProps} onPress={handlePress} cursor="pointer" userSelect="none" />
}

// export const AnalyticStack = styled(AnalyticStackFrame)
