import { gql } from '@apollo/client'

export const ApplyCoupon = gql`
  mutation Mutation($data: CouponInput) {
    applyCoupon(data: $data) {
      cart {
        address {
          id
          firstname
          lastname
          address1
          address2
          city
          zipcode
          phone
          alternativePhone
          company
          state_id
          state
          country_id
          user_id
          label
          preferences
        }
        id
        state
        totalPrice
        discount
        finalPrice
        lineItems {
          id
          quantity
          product {
            id
            image
            name
            price
          }
          price
          total
          productId
          name
        }
      }
    }
  }
`

export const RemoveCoupon = gql`
  mutation Mutation($data: CouponInput) {
    removeCoupon(data: $data) {
      cart {
        address {
          id
          firstname
          lastname
          address1
          address2
          city
          zipcode
          phone
          alternativePhone
          company
          state_id
          state
          country_id
          user_id
          label
          preferences
        }
        id
        state
        totalPrice
        discount
        finalPrice
        lineItems {
          id
          quantity
          product {
            id
            image
            name
            price
          }
          price
          total
          productId
          name
        }
      }
    }
  }
`
