import * as React from 'react'

export interface RecentPostCountContextType {
  recentPostCount: number
  setRecentPostCount: React.Dispatch<React.SetStateAction<number>>
}

export const RecentPostCountContext = React.createContext<RecentPostCountContextType>(
  {} as RecentPostCountContextType
)

export const RecentPostCountProvider = ({ children }: { children?: React.ReactNode }) => {
  const [recentPostCount, setRecentPostCount] = React.useState(5)

  return (
    <RecentPostCountContext.Provider value={{ recentPostCount, setRecentPostCount }}>
      {children}
    </RecentPostCountContext.Provider>
  )
}
