import { createMedia } from '@tamagui/react-native-media-driver'

export const media = createMedia({
  xs: { maxWidth: 767 },
  sm: { maxWidth: 767 },
  md: { maxWidth: 1023 },
  lg: { maxWidth: 1439 },

  gtXs: { minWidth: 767 + 1 },
  gtSm: { minWidth: 767 + 1 },
  gtMd: { minWidth: 1023 + 1 },
  gtLg: { minWidth: 1439 + 1 },
})

// note all the non "gt" ones should be true to start to match mobile-first
// we're aiming for "xs" to be the default to "gtXs" true too
export const mediaQueryDefaultActive = {
  xs: true,
  sm: true,
  md: true,
  lg: true,

  gtXs: false,
  gtSm: false,
  gtMd: false,
  gtLg: false,
}
