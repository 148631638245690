import { gql } from '@apollo/client'

export const UserCreditScore = gql`
  mutation documentCreditReportCreate {
    documentCreditReportCreate {
      status
      product
      status_data
      screen
    }
  }
`
