import { gql } from '@apollo/client'

export const UserPurposePayment = gql`
  mutation DocumentPurposePayment($data: PurposePaymentInput) {
    documentPurposePayment(data: $data) {
      uuid
      url
    }
  }
`
