import { gql } from '@apollo/client'

export const setUserPin = gql`
  mutation UserSetPassword($password: String!, $confirmPassword: String!) {
    UserSetPassword(data: { confirmPassword: $confirmPassword, password: $password }) {
      status
      product
      status_data
      screen
    }
  }
`
