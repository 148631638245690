import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { parse } from 'cookie'
import { GetServerSidePropsContext } from 'next'
import * as React from 'react'
import { Platform } from 'react-native'
import { getBaseUrl } from '../../utils/getBaseUrl'
import { storage } from '../../utils/storage'
import { UpdateNowContext } from '../update-now/UpdateNowProvider'
import { useApollo } from './apolloClient'
import { sprinkles } from './fraudDetection'

const cache = new InMemoryCache({})

export const apolloContext = (
  ctx: GetServerSidePropsContext | string,
  setUpdateNeeded?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const httpLink = createHttpLink({
    uri: getBaseUrl(),
    fetchOptions: {
      credentials: Platform.OS !== 'web' ? 'omit' : 'include',
    },
    credentials: Platform.OS !== 'web' ? 'omit' : 'include',
  })

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors)
      for (const error of graphQLErrors) {
        if (error.message === 'update_needed') {
          setUpdateNeeded?.(true)
          break
        }
      }
  })

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(() => {
      const headers: Record<string, string | string[] | undefined> = {}
      headers.cookie =
        (typeof ctx !== 'string' ? ctx.req.headers.cookie ?? '' : '') +
        storage
          .getAllKeys()
          .map((key) => {
            if (key !== '@preferred_theme') {
              return `${key}=${storage.getString(key)}`
            }
          })
          .join('; ')

      // TODO: This is a temp fix. This should be the user's location
      headers['latitude'] = '0'
      headers['longitude'] = '0'

      if (typeof ctx !== 'string') {
        Object.keys(ctx.req.headers).map((header) => {
          if (header!.startsWith('x-b3')) {
            headers[header] = ctx.req.headers[header]
          }
        })
      }

      return { headers }
    })

    return forward(operation).map((response) => {
      const context = operation.getContext()
      const {
        response: { headers },
      } = context

      if (typeof ctx !== 'string') {
        ctx.res.setHeader('Set-Cookie', headers.get('set-cookie'))
      } else {
        let setCookie = headers.get('set-cookie') ?? []
        if (typeof setCookie === 'string') {
          setCookie = [setCookie]
        }
        if (setCookie.length > 0) {
          for (const cookie of setCookie) {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            const {
              domain,
              'http-only': httpOnly,
              path,
              priority,
              'same-site': sameSite,
              secure,
              expires,
              'max-age': maxAge,
              ...rest
            } = parse(cookie)
            /* eslint-enable @typescript-eslint/no-unused-vars */
            for (const key in rest) {
              // store only if not expired
              // expiry is decided by expiry key if present
              // or by max age from now.
              if (
                (expires === undefined || Date.parse(expires) > Date.now()) &&
                (maxAge === undefined || Date.now() + parseInt(maxAge) > Date.now())
              ) {
                storage.set(key, rest[key]!)
              } else {
                // it's expired. delete
                storage.delete(key)
              }
            }
          }
        }
      }

      return response
    })
  })

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, authMiddleware, httpLink]),
    cache,
    credentials: Platform.OS !== 'web' ? 'omit' : 'include',
    // TODO: remove network-only when graphql works with caching
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })

  return client
}

export const ApolloClientProvider = ({
  children,
  pageProps,
}: {
  children: React.ReactNode
  pageProps: Record<string, unknown>
}) => {
  const { setUpdateNeeded } = React.useContext(UpdateNowContext)

  const client = useApollo(pageProps, setUpdateNeeded)

  React.useEffect(() => {
    const listener = sprinkles()

    return listener
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
