// should roughly map to button/input etc height at each level
// fonts should match that height/lineHeight at each stop
// so these are really non-linear on purpose
// why?
//   - at sizes <1, used for fine grained things (borders, smallest paddingY)
//     - so smallest padY should be roughly 1-4px so it can join with lineHeight
//   - at sizes >=1, have to consider "pressability" (jumps up)
//   - after that it should go upwards somewhat naturally
//   - H1 / headings top out at 10 naturally, so after 10 we can go upwards faster
//  but also one more wrinkle...
//  space is used in conjunction with size
//  i'm setting space to generally just a fixed fraction of size (~1/3-2/3 still fine tuning)
export const size = {
  $0: 0,
  '$0.25': 1,
  '$0.5': 1.5,
  '$0.75': 2,
  $1: 4,
  '$1.25': 8,
  '$1.5': 12,
  '$1.75': 16,
  $2: 18,
  '$2.25': 20,
  '$2.5': 24,
  $3: 32,
  '$3.5': 36,
  $4: 40,
  $true: 40,
  '$4.5': 48,
  $5: 52,
  $6: 64,
  $7: 72,
  $8: 80,
  $9: 96,
  $10: 112,
  $11: 120,
  '$11.25': 128,
  $12: 144,
  '$12.25': 156,
  $13: 164,
  '$13.25': 170,
  $14: 200,
  $15: 224,
  $16: 256,
  '$16.75': 272,
  $17: 300,
  '$17.25': 320,
  $18: 360,
  $19: 500,
  $20: 512,
  $24: 656,
  $39: 1024,
  $40: 1440,
}

export type SizeKeysIn = keyof typeof size
export type Sizes = {
  [Key in SizeKeysIn extends `$${infer Key}` ? Key : SizeKeysIn]: number
}
export type SizeKeys = `${keyof Sizes extends `${infer K}` ? K : never}`
