import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M9.667 12a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2h-4a1 1 0 0 1-1-1z" />
      <Path d="M1.718 3.684a1 1 0 0 1 1.265-.632l.406.135c.835.278 1.541.514 2.096.772.59.274 1.102.615 1.49 1.153s.549 1.132.623 1.778l.012.111h14.405c1.301 0 2.39 0 3.222.123.869.129 1.706.424 2.235 1.225s.47 1.688.246 2.537c-.215.814-.643 1.814-1.156 3.01l-.622 1.452c-.235.549-.443 1.033-.658 1.415-.233.414-.518.791-.948 1.075s-.889.397-1.361.448c-.437.047-.962.047-1.56.047H8.206c.098.179.212.326.341.455.369.369.887.61 1.865.741 1.007.135 2.342.137 4.256.137h10.667a1 1 0 0 1 0 2h-10.74c-1.823 0-3.293 0-4.449-.155-1.2-.161-2.211-.507-3.013-1.309s-1.148-1.813-1.309-3.013c-.155-1.156-.155-2.626-.155-4.449V9.178c0-.951-.002-1.58-.056-2.061-.052-.456-.143-.676-.258-.836s-.295-.316-.711-.509c-.439-.204-1.036-.405-1.937-.705l-.353-.118a1 1 0 0 1-.632-1.265zm6.025 12.65h13.62c.661 0 1.078-.001 1.394-.035.292-.032.405-.083.475-.13s.162-.129.306-.385c.156-.277.321-.66.582-1.268l.571-1.333c.553-1.29.92-2.153 1.092-2.807.168-.635.083-.828.018-.925s-.208-.252-.858-.348c-.669-.099-1.607-.102-3.01-.102H7.666v3.667c0 1.563.001 2.739.076 3.667zM10 29a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm-1-3a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm10 0a3 3 0 1 0 6 0 3 3 0 0 0-6 0zm3 1a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </Svg>
  )
}

Icon.displayName = 'ShoppingCart'

export const ShoppingCart = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
