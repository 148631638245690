import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const getCartData = gql`
  query Cart($recentPostCount: Float = 5) {
    me {
      billing {
        balance
        bill
      }
      emi_settings {
        rate
        duration
        min_requirement
      }
      cart {
        id
        state
        totalPrice
        discount
        finalPrice
        lineItems {
          id
          quantity
          product {
            image
            name
            originalPrice
            price
            vendor
          }
          price
          total
          productId
          name
        }
        address {
          id
          firstname
          lastname
          address1
          address2
          city
          zipcode
          phone
          alternativePhone
          company
          state_id
          state
        }
        promotions {
          id
          name
          coupon
          promoDiscount
          removable
        }
      }
      addresses {
        address1
        address2
        alternativePhone
        city
        id
        firstname
        lastname
        zipcode
        phone
        company
        state_id
        state
      }
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
