import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const GetBlogData = gql`
  query GetBlogData($recentPostCount: Float = 10) {
    ...RecentPostFragment
    posts {
      recent(perPage: $recentPostCount) {
        content
      }
    }
  }
  ${RecentPostFragment}
`
