import { gql } from '@apollo/client'
export const getTransactions = gql`
  query GetTransactions($page: Float, $month: Float, $year: Float) {
    me {
      transaction_history(page: $page, month: $month, year: $year) {
        uuid
        amount
        billable_at
        category
        comments {
          key
          params {
            service_number
          }
        }
        description {
          key
          params {
            service_number
          }
        }
        emi_details {
          amount
          billable_at
          emi_seq
          count
        }
        entity_id
        entity_type
        fee
        is_emi_disabled
        operator {
          name
          picture_id
        }
        status
      }
    }
    posts {
      recent(count: 5) {
        name
        title
      }
    }
  }
`
