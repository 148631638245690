import { gql } from "@apollo/client";

export const BannersFragment = gql`
  fragment BannersFragment on Query {
    banners {
      image
      key
      link
    }
  }
`