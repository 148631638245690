import { tamagui } from '@my/ui'
import type { ThemeableStackProps } from 'tamagui'
const { ThemeableStack } = tamagui

export function ViewPort({ $gtXs, $gtMd, ...props }: ThemeableStackProps) {
  return (
    <ThemeableStack
      $gtXs={{ maw: '$39', ...$gtXs }}
      $gtMd={{ maw: '$40', ...$gtMd }}
      mx="auto"
      width="100%"
      {...props}
    />
  )
}
