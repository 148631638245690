// import dynamic from 'next/dynamic'
// import * as React from 'react'
import type { Client } from '@bugsnag/js'

let client: Client | undefined

export const bugsnag = async () => {
  if (client === undefined) {
    const [Bugsnag, BugsnagPluginReact] = await Promise.all([
      import('@bugsnag/js').then((module) => module.default),
      import('@bugsnag/plugin-react').then((module) => module.default),
    ])
    client = Bugsnag.start({
      apiKey: '4a4cfd4caf8e4a2d7a47d053d1417670',
      plugins: [new BugsnagPluginReact()],
    })
  }
  return client
}

// if (typeof window !== 'undefined') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const BugsnagPerformance = require('@bugsnag/browser-performance').default
//   BugsnagPerformance.start({ apiKey: '4a4cfd4caf8e4a2d7a47d053d1417670' })
// }

// export const ErrorBoundary = dynamic(
//   () =>
//     import('@bugsnag/js').then((module) =>
//       module.default.getPlugin('react')!.createErrorBoundary(React)
//     ),
//   {
//     loading: ({ children }: { children: React.ReactNode }) => <>{children}</>,
//   }
// )
