import { useMutation } from '@apollo/client'
import { DeviceCreate } from '@my/api'
import { getToken, init } from '@my/notifications'
import * as React from 'react'

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [createdDeviceEntry] = useMutation(DeviceCreate)

  React.useEffect(() => {
    init({
      apiKey: 'AIzaSyCq3QiEIhxWGgkeNNb24uMLR7y2rPFDQFE',
      projectId: 'paysay-d69fe',
      messagingSenderId: '852573741893',
      appId: '1:852573741893:web:e61cc058ce2c6c1647542c',
    })
    getToken(console.log).then((token: string | undefined) => {
      // DeviceCreate
      if (token) {
        createdDeviceEntry({
          variables: {
            data: {
              token,
            },
          },
        })
      }
    })
  }, [createdDeviceEntry])

  return <>{children}</>
}
