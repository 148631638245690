import { createFont } from 'tamagui'

const sizes = {
  size: {
    1: 10,
    2: 12,
    3: 14,
    4: 16,
    5: 18,
    6: 20,
    7: 10,
    8: 12,
    9: 14,
    true: 16,
    10: 16,
    11: 18,
    12: 20,
    13: 22,
    14: 24,
    15: 32,
    16: 40,
    17: 48,
  },
  lineHeight: {
    1: 20,
    2: 20,
    3: 24,
    4: 28,
    5: 30,
    6: 32,
    7: 36,
    8: 20,
    9: 24,
    true: 28,
    10: 28,
    11: 30,
    12: 32,
    13: 34,
    14: 36,
    15: 44,
    16: 54,
    17: 60,
  },
  letterSpacing: {
    1: -0.2,
    2: -0.2,
    3: -0.2,
    4: -0.3,
    5: -0.3,
    6: -0.3,
    7: -0.3,
    8: -0.2,
    9: -0.2,
    true: -0.3,
    10: -0.3,
    11: -0.3,
    12: -0.3,
    13: -0.3,
    14: -0.5,
    15: -0.5,
    16: -1,
    17: -1.75,
  },
  weight: {
    1: 400,
    true: 400,
    2: 500,
    3: 600,
    5: 1000,
  },
  face: {
    // pass in weights as keys
    500: { normal: 'PoppinsMedium' },
    600: { normal: 'PoppinsBold' },
  },
}

export const poppins = createFont({
  family: 'Poppins',
  ...sizes,
})

export const workSans = createFont({
  family: 'WorkSans',
  ...sizes,
})
