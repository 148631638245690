import { gql } from '@apollo/client'
export const getEmiSettings = gql`
  query Query {
    me {
      emi_settings {
        rate
        duration
        min_requirement
      }
    }
  }
`
