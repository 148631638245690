import { Variable } from '@tamagui/web'
import {
  blue,
  redish,
  olivGreen,
  orange,
  red,
  green,
  gray,
  aliceBlue,
} from './colors'


export const colorTokens = {
  light: {
    blue,
    redish,
    olivGreen,
    orange,
    red,
    green,
    gray,
    aliceBlue,
  },
  dark: {
    blue,
    redish,
    olivGreen,
    orange,
    red,
    green,
    gray,
    aliceBlue,
  },
}

export const darkColors = {
  ...colorTokens.dark.blue,
  ...colorTokens.dark.redish,
  ...colorTokens.dark.olivGreen,
  ...colorTokens.dark.orange,
  ...colorTokens.dark.red,
  ...colorTokens.dark.green,
  ...colorTokens.dark.gray,
  ...colorTokens.dark.aliceBlue,
}

export const lightColors = {
  ...colorTokens.light.blue,
  ...colorTokens.light.redish,
  ...colorTokens.light.olivGreen,
  ...colorTokens.light.orange,
  ...colorTokens.light.red,
  ...colorTokens.light.green,
  ...colorTokens.light.gray,
  ...colorTokens.light.aliceBlue,
}

export const color = {
  ...postfixObjKeys(lightColors, 'Light'),
  ...postfixObjKeys(darkColors, 'Dark'),
}

function postfixObjKeys<A extends { [key: string]: Variable<string> | string }, B extends string>(
  obj: A,
  postfix: B
): {
  [Key in `${keyof A extends string ? keyof A : never}${B}`]: Variable<string> | string
} {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [`${k}${postfix}`, v])) as never
}
