import { gql } from '@apollo/client'
import { RecentPostFragment } from '../fragments'

export const GetProductDetails = gql`
  query Shopping($slug: String, $recentPostCount: Float = 5) {
    product(slug: $slug) {
      description
      id
      image {
        original_url
        alt
      }
      primary_variant {
        emi
        slug
        id
        images {
          original_url
          alt
        }
        compare_at_price
        price
      }
      name
      option_types
      options
      compare_at_price
      price
      variants {
        id
        slug
        images {
          original_url
          alt
        }
        options
        compare_at_price
        price
      }
      vendor {
        id
        name
        slug
      }
    }
    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
