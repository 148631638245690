import { gql } from '@apollo/client'

export const ServiceCreate = gql`
  mutation ServiceCreate($amount: Float!, $operator_id: Float!, $serviceCode: String!, $service_number: String) {
    serviceCreate(data: { amount: $amount, operator_id: $operator_id, serviceCode: $serviceCode, service_number: $service_number }) {
      id
      amount
    }
  }
`
