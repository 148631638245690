import { useFragment } from '@apollo/client'
import { RecentPostFragment } from '@my/api'
import { BodyText, H4, LabelText, tamagui } from '@my/ui'
import * as React from 'react'
import { SolitoImage } from 'solito/image'
import { Link } from 'solito/link'
import PLAY_STORE from '../assets/googleplay.svg'
import { usePlayStoreUrl } from '../hooks/usePlayStoreUrl'
import { Message, Phone } from '../icons'
import { RecentPostCountContext } from '../provider/RecentPostCountProvider'
import { PostType } from '../types'
import { ViewPort } from './Viewport'

const { Stack, ThemeableStack, Separator, XStack, YStack } = tamagui

export const Footer = () => {
  const { recentPostCount } = React.useContext(RecentPostCountContext)

  const { data } = useFragment({
    fragment: RecentPostFragment,
    fragmentName: 'RecentPostFragment',
    from: {
      __typename: 'Query',
      id: '{}',
    },
    variables: {
      recentPostCount,
    },
  })

  const posts: PostType[] = data?.posts?.recent?.slice?.(0, 5) ?? []

  const url = usePlayStoreUrl('utm_channel=website&utm_source=footer')

  return (
    <ThemeableStack theme="inverse" backgroundColor="$background">
      <ViewPort>
        <ThemeableStack
          gap="$10"
          fd="column"
          p="$10"
          $gtXs={{ jc: 'space-between', gap: '$12', fd: 'row' }}
          $gtMd={{ gap: '$14', py: '$17', px: '$27' }}
        >
          <YStack f={1} gap="$10" fb={undefined} $gtXs={{ fb: 0 }}>
            <H4>Download the app now</H4>
            <Link href={url}>
              <YStack h={65} w={198} $sm={{ h: 28, w: 84 }} $gtMd={{ h: '$6', w: '$14' }}>
                <SolitoImage alt="playStore" src={PLAY_STORE} fill contentFit="cover" />
              </YStack>
            </Link>
            <YStack gap="$6">
              <BodyText>
                Our mission at Paysay is to give easy access to daily use credit to everyone and
                enable financial freedom with dignity.
              </BodyText>
              <XStack gap="$6" ai="center">
                <Message size="$2.5" />
                <BodyText weight="$4" href="mailto:support@paysay.in">
                  support@paysay.in
                </BodyText>
              </XStack>
              <XStack gap="$6" ai="center">
                <Phone size="$2.5" />
                <BodyText weight="$4" href="tel:+919311227993">
                  +91-9311-227-993
                </BodyText>
              </XStack>
            </YStack>
          </YStack>
          <Separator $gtXs={{ dsp: 'none' }} />
          <YStack f={1} gap="$10" fb={undefined} $gtXs={{ fb: 0 }}>
            <H4>Explore</H4>
            <XStack gap="$6" fw="wrap">
              <Stack fb="48%" $gtXs={{ fb: '100%' }}>
                <LabelText weight="$4" href="/blog">
                  Blog
                </LabelText>
              </Stack>
              <Stack fb="48%" $gtXs={{ fb: '100%' }}>
                <LabelText weight="$4" href="/tnc">
                  Terms & Conditions
                </LabelText>
              </Stack>
              <Stack fb="48%" $gtXs={{ fb: '100%' }}>
                <LabelText weight="$4" href="/privacy">
                  Privacy Policy
                </LabelText>
              </Stack>
              <Stack fb="48%" $gtXs={{ fb: '100%' }}>
                <LabelText weight="$4" href="/grievance-redressal">
                  Grievance Redressal
                </LabelText>
              </Stack>
              <Stack fb="48%" $gtXs={{ fb: '100%' }}>
                <LabelText weight="$4" href="/fair-practice-code">
                  Fair Practice Code
                </LabelText>
              </Stack>
              {/* <Link href="/partners" fb="48%" $gtXs={{ fb: '100%' }}>
                <Label>Our Partners</Label>
              </Link> */}
            </XStack>
          </YStack>
          <Separator $gtXs={{ dsp: 'none' }} />
          <YStack f={1} gap="$10" fb={undefined} $gtXs={{ fb: 0 }}>
            <H4>Recent Posts</H4>
            <YStack gap="$6">
              {posts.map(({ name, title }) => (
                <LabelText weight="$4" key={name} href={`/${name}`}>
                  {title}
                </LabelText>
              ))}
            </YStack>
          </YStack>
        </ThemeableStack>
        <Separator />
        <BodyText ta="center" py="$10">
          Copyright 2023 V. N. Credits Ltd
        </BodyText>
      </ViewPort>
    </ThemeableStack>
  )
}
