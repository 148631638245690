import { gql } from "@apollo/client";
import { BillingFragment } from "./fragments";

export const GetEntityPaymentPending = gql`
  query ($entityId: Float, $entityType: String) {
    me {
      service(entity_id: $entityId, entity_type: $entityType) {
        amount
        id
        number
        operator {
          id
          name
          picture_id
        }
      }
      billing {
        ...BillingFragment
      }
    }
  }
  ${BillingFragment}
`