import { gql } from '@apollo/client'

export const UserAadhaarOtp = gql`
  mutation DocumentAadhaarXml($data: AadhaarXmlInput) {
    documentAadhaarXml(data: $data) {
      status
      product
      status_data
      screen
    }
  }
`
