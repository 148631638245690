import { gql } from '@apollo/client'

export const getCartId = gql`
  query CartId {
    me {
      cart {
        id
      }
    }
  }
`
