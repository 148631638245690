export const blue = {
  blue1: 'hsl(0, 0%, 100%)',
  blue2: 'hsl(211, 31%, 95%)',
  blue3: 'hsl(211, 20%, 86%)',
  blue4: 'hsl(211, 21%, 76%)',
  blue5: 'hsl(211, 21%, 66%)',
  blue6: 'hsl(211, 21%, 56%)',
  blue7: 'hsl(211, 21%, 46%)',
  blue8: 'hsl(211, 32%, 27%)',
  blue9: 'hsl(211, 32%, 22%)',
  blue10: 'hsl(211, 33%, 16%)',
}

export const redish = {
  redish1: 'hsl(0, 0%, 100%)',
  redish2: 'hsl(311, 31%, 95%)',
  redish3: 'hsl(311, 20%, 86%)',
  redish4: 'hsl(311, 21%, 76%)',
  redish5: 'hsl(311, 21%, 66%)',
  redish6: 'hsl(311, 21%, 56%)',
  redish7: 'hsl(311, 21%, 46%)',
  redish8: 'hsl(311, 32%, 27%)',
  redish9: 'hsl(311, 32%, 22%)',
  redish10: 'hsl(311, 33%, 16%)',
}

export const olivGreen = {
  olivGreen1: 'hsl(0, 0%, 100%)',
  olivGreen2: 'hsl(91, 31%, 95%)',
  olivGreen3: 'hsl(91, 20%, 86%)',
  olivGreen4: 'hsl(91, 21%, 76%)',
  olivGreen5: 'hsl(91, 21%, 66%)',
  olivGreen6: 'hsl(91, 21%, 56%)',
  olivGreen7: 'hsl(91, 21%, 46%)',
  olivGreen8: 'hsl(91, 32%, 27%)',
  olivGreen9: 'hsl(91, 32%, 22%)',
  olivGreen10: 'hsl(91, 33%, 16%)',
}

export const orange = {
  orange1: 'hsl(0, 0%, 100%)',
  orange2: 'hsl(30, 100%, 96%)',
  orange3: 'hsl(29, 100%, 92%)',
  orange4: 'hsl(29, 100%, 85%)',
  orange5: 'hsl(29, 100%, 79%)',
  orange6: 'hsl(29, 100%, 69%)',
  orange7: 'hsl(29, 97%, 57%)',
  orange8: 'hsl(29, 97%, 54%)',
  orange9: 'hsl(29, 99%, 46%)',
  orange10: 'hsl(29, 100%, 41%)',
}

export const red = {
  red1: 'hsl(0, 0%, 100%)',
  red2: 'hsl(5, 100%, 98%)',
  red3: 'hsl(4, 97%, 94%)',
  red4: 'hsl(4, 100%, 88%)',
  red5: 'hsl(4, 100%, 80%)',
  red6: 'hsl(3, 100%, 72%)',
  red7: 'hsl(4, 100%, 65%)',
  red8: 'hsl(0, 100%, 61%)',
  red9: 'hsl(4, 83%, 50%)',
  red10: 'hsl(4, 88%, 44%)',
}

export const green = {
  green1: 'hsl(0, 0%, 100%)',
  green2: 'hsl(152, 100%, 97%)',
  green3: 'hsl(150, 100%, 92%)',
  green4: 'hsl(148, 65%, 73%)',
  green5: 'hsl(148, 67%, 64%)',
  green6: 'hsl(150, 64%, 50%)',
  green7: 'hsl(150, 72%, 41%)',
  green8: 'hsl(150, 82%, 36%)',
  green9: 'hsl(150, 88%, 32%)',
  green10: 'hsl(150, 94%, 27%)',
}

export const gray = {
  gray1: '#FFFFFF',
  gray2: '#F4F4F4',
  gray3: '#E0E0E0',
  gray4: '#C4C4C4',
  gray5: '#A8A8A8',
  gray6: '#8C8C8C',
  gray7: '#707070',
  gray8: '#545454',
  gray9: '#434343',
  gray10: '#222222',
}

export const aliceBlue = {
  aliceBlue1: 'hsl(0, 0%, 100%)',
  aliceBlue2: 'hsl(210, 100%, 95)',
  aliceBlue3: 'hsl(210, 73%, 87%)',
  aliceBlue4: 'hsl(210, 70%, 79%)',
  aliceBlue5: 'hsl(210, 68%, 71%)',
  aliceBlue6: 'hsl(210, 68%, 64%)',
  aliceBlue7: 'hsl(210, 67%, 56%)',
  aliceBlue8: 'hsl(210, 73%, 48%)',
  aliceBlue9: 'hsl(210, 100%, 40%)',
  aliceBlue10: 'hsl(209, 100%, 35%)',
}
