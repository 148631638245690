import { gql } from '@apollo/client'

export const makePayment = gql`
  mutation Payment($data: PaymentInput) {
    payment(data: $data) {
        code
        uuid
        id
        url
    }
  }
`