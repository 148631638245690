import { gql } from "@apollo/client";
import { BillingWithExtensionPlansFragment, RecentPostFragment } from "./fragments";

export const GetBilling = gql`
  query GetBilling($recentPostCount: Float = 5) {
    me {
      billing {
        ...BillingWithExtensionPlansFragment
      }
    }
    ...RecentPostFragment
  }
  ${BillingWithExtensionPlansFragment}
  ${RecentPostFragment}
`;
