import { cookieJar } from './cookieJar'
import { getBaseUrl } from './getBaseUrl'

const getCookie = (key: string) => {
  const cookies = cookieJar.getCookiesSync(getBaseUrl())
  if (cookies) {
    for (const cookie of cookies) {
      if (cookie.key === key) {
        return cookie
      }
    }
  }
}

export const getAuthToken = () => {
  return getCookie('authorization')?.value
}

export const getInstallReferrer = () => {
  return getCookie('install-referrer')?.value
}
