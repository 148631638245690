import { gql } from '@apollo/client'
import { RecentPostFragment } from './fragments'

export const GoldPriceAndVaultBalance = gql`
  query GoldPriceAndVaultBalance($recentPostCount: Float = 5) {
    services {
      id
      name
      code
      enabled
      sort_order
      picture_id
    }
    gold {
      buy_price {
        current_price
        applicable_tax
        rate_id
        weight_rounding {
          round_direction
          decimal_places
        }
        amount_rounding {
          round_direction
          decimal_places
        }
        expires_at
      }
      sell_price {
        current_price
        applicable_tax
        rate_id
        weight_rounding {
          round_direction
          decimal_places
        }
        amount_rounding {
          round_direction
          decimal_places
        }
        expires_at
      }
    }
    me {
      gold_balance
    }

    ...RecentPostFragment
  }
  ${RecentPostFragment}
`
