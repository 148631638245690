import { gql } from '@apollo/client'

export const UserCreditScoreSeen = gql`
  mutation documentCreditReportPut {
    documentCreditReportPut {
        status
        product
        status_data
        screen
    }
  }
`
