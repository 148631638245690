import { gql } from '@apollo/client'

export const addToCart = gql`
  mutation AddToCart($data: AddtoCartInput) {
    addToCart(data: $data) {
      id
      lineItems {
        id
      }
    }
  }
`
