import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M21.333 12c0 .8-.533 1.333-1.333 1.333S18.667 12.8 18.667 12 19.2 10.667 20 10.667s1.333.533 1.333 1.333zm-8 0c0 .8-.533 1.333-1.333 1.333S10.667 12.8 10.667 12 11.2 10.667 12 10.667s1.333.533 1.333 1.333z" />
      <Path d="M28.934 22c-.133-1.6-.533-3.6-1.067-6v-.133c-.4-1.733-.667-3.067-.933-4.267-.4-1.067-.8-2-1.6-2.8-.267-.267-.533-.533-.8-.667-.933-.533-1.867-.8-3.067-.933h-.533v-.533c0-2.8-2.267-5.067-5.067-5.067s-4.933 2.267-4.933 5.067v.4h-.533c-1.067.133-2.133.4-2.933 1.067-.267.133-.533.4-.8.667-.8.8-1.2 1.6-1.6 2.8-.4 1.067-.667 2.533-.933 4.267v.133c-.533 2.533-.933 4.533-1.067 6-.133 1.6 0 2.933.8 4.133.267.4.533.8.933 1.067 1.067.933 2.267 1.467 3.867 1.6s3.6.133 6.133.133h2.4c2.533 0 4.533 0 6.133-.133 1.6-.267 2.933-.667 3.867-1.6.4-.267.667-.667.933-1.067.8-1.2.933-2.533.8-4.133zm-16-15.333c0-1.6 1.333-2.933 2.933-2.933S18.8 5.067 18.8 6.667v.4h-6.134v-.4h.267zm13.467 18.4c-.133.267-.4.533-.667.8-.533.533-1.333.933-2.8 1.067s-3.333.133-6 .133h-2.267c-2.667 0-4.533 0-6-.133-1.2-.267-2-.667-2.533-1.2-.267-.267-.4-.533-.667-.8-.4-.667-.533-1.467-.533-2.933.133-1.467.533-3.333.933-5.867.533-1.733.8-2.933 1.067-4 .267-.933.667-1.467 1.067-1.867.133-.133.4-.267.533-.533a6.717 6.717 0 0 1 2.133-.667c1.067-.133 2.267-.133 4.133-.133h2.267c1.867 0 3.2 0 4.133.133s1.6.267 2.133.667c.267.133.4.267.533.533.4.4.8.933 1.067 2 .267.933.533 2.267.933 4.133.533 2.533.933 4.4.933 5.867.267 1.333.133 2.133-.4 2.8z" />
    </Svg>
  )
}

Icon.displayName = 'ShoppingBag'

export const ShoppingBag = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
