import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M13.258 4.333h5.484c2.45 0 4.391 0 5.91.204 1.563.21 2.829.653 3.826 1.651s1.441 2.263 1.651 3.826c.204 1.519.204 3.46.204 5.91v.15c0 2.45 0 4.391-.204 5.91-.21 1.563-.653 2.829-1.651 3.826s-2.263 1.441-3.826 1.651c-1.519.204-3.46.204-5.91.204h-5.484c-2.45 0-4.391 0-5.91-.204-1.563-.21-2.829-.653-3.826-1.651s-1.441-2.263-1.651-3.826c-.204-1.519-.204-3.46-.204-5.91v-.15c0-2.45 0-4.391.204-5.91.21-1.563.653-2.829 1.651-3.826s2.263-1.441 3.826-1.651c1.519-.204 3.46-.204 5.91-.204zM7.614 6.52c-1.341.18-2.114.519-2.679 1.083s-.902 1.337-1.083 2.679c-.184 1.37-.186 3.176-.186 5.719s.002 4.349.186 5.719c.18 1.341.519 2.114 1.083 2.679s1.337.903 2.679 1.083c1.37.184 3.176.186 5.719.186h5.333c2.542 0 4.349-.002 5.719-.186 1.341-.18 2.114-.519 2.679-1.083s.903-1.337 1.083-2.679c.184-1.37.186-3.177.186-5.719s-.002-4.349-.186-5.719c-.18-1.341-.519-2.114-1.083-2.679s-1.337-.902-2.679-1.083c-1.37-.184-3.177-.186-5.719-.186h-5.333c-2.542 0-4.349.002-5.719.186zm-.382 3.506a1 1 0 0 1 1.408-.128l2.879 2.399c1.244 1.037 2.108 1.754 2.837 2.223.706.454 1.184.606 1.645.606s.939-.152 1.645-.606c.729-.469 1.593-1.186 2.837-2.223l2.879-2.399a1 1 0 1 1 1.28 1.536l-2.929 2.441c-1.182.985-2.14 1.783-2.985 2.327-.881.566-1.738.924-2.726.924s-1.846-.358-2.726-.924c-.845-.544-1.803-1.342-2.985-2.327l-2.929-2.441a1 1 0 0 1-.128-1.408z" />
    </Svg>
  )
}

Icon.displayName = 'Message'

export const Message = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
