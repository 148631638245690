import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import * as React from 'react'
import { Platform } from 'react-native'
import type { CookieJar } from 'tough-cookie'
import { cookieJar } from '../../utils/cookieJar'
import { getBaseUrl } from '../../utils/getBaseUrl'

export type Props = Record<string, unknown>

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const httpLink = new HttpLink({
  uri: getBaseUrl(),
  credentials: Platform.OS !== 'web' ? 'omit' : 'include',
})

const errorLink = (setUpdateNeeded?: React.Dispatch<React.SetStateAction<boolean>>) => onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      if (graphQLError.message === 'update_needed') {
        setUpdateNeeded?.(true)
        break
      }
    }
  }
})

function createApolloClient(cookieJar: CookieJar, setUpdateNeeded?: React.Dispatch<React.SetStateAction<boolean>>) {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(() => {
      const headers: Record<string, string> = {}
      headers.cookie = cookieJar.getCookieStringSync(getBaseUrl())

      // TODO: This is a temp fix. This should be the user's location
      headers['latitude'] = '0'
      headers['longitude'] = '0'

      return { headers }
    })

    return forward(operation).map((response) => {
      const context = operation.getContext()
      const {
        response: { headers },
      } = context

      if (headers.get('set-cookie') !== null) {
        cookieJar.setCookie(headers.get('set-cookie'), getBaseUrl())
      }

      return response
    })
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink(setUpdateNeeded), authMiddleware, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        State: {
          keyFields: []
        },
        Post: {
          keyFields: []
        },
        Recent: {
          keyFields: ['name']
        },
        Banner: {
          keyFields: ["key"],
        },
        Billing: {
          keyFields: []
        },
        Shopping: {
          keyFields: [],
        },
        BaseCategory: {
          keyFields: ["slug"]
        },
        Category: {
          keyFields: ["slug"]
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      }
    },
  })
}

export function initializeApollo<Props>(cookieJar: CookieJar, initialState?: Props, setUpdateNeeded?: React.Dispatch<React.SetStateAction<boolean>>) {
  const _apolloClient = apolloClient ?? createApolloClient(cookieJar, setUpdateNeeded)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState<Props extends {
  [APOLLO_STATE_PROP_NAME]?: unknown
  [key: string]: unknown
}>(client: ApolloClient<NormalizedCacheObject>, pageProps: { props: Props }) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo<
  Props extends {
    [APOLLO_STATE_PROP_NAME]?: unknown
    [key: string]: unknown
  }
>(props: Props, setUpdateNeeded?: React.Dispatch<React.SetStateAction<boolean>>) {
  const state = props?.[APOLLO_STATE_PROP_NAME]
  const store = React.useMemo(() => initializeApollo(cookieJar, state as Props | undefined, setUpdateNeeded), [setUpdateNeeded, state])
  return store
}