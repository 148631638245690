import { LinkProps, TextLink } from 'solito/link'
import { GetProps, Text, styled } from 'tamagui'

const SizableTextFrame = styled(Text, {
  fontFamily: '$body',
  userSelect: 'auto',
  color: '$color',
  variants: {
    size: (val, { font }) => {
      val = font?.size?.[val] === undefined ? '$12' : val
      return {
        fontSize: font?.size[val].val,
        lineHeight: font?.lineHeight[val].val,
        letterSpacing: font?.letterSpacing[val].val,
      }
    },
    weight: (val = '$true', { font }) => {
      return {
        fontWeight: font?.weight?.[val]?.val,
      }
    },
  } as const,

  defaultVariants: {
    size: '$12',
    weight: '$true',
  },
})

export type SizableTextProps = GetProps<typeof SizableTextFrame> & Partial<LinkProps>

export const SizableText = SizableTextFrame.styleable<SizableTextProps>(
  ({ as, href, replace, scroll, shallow, ...rest }: SizableTextProps, ref) => {
    if (href) {
      return (
        <TextLink as={as} href={href} replace={replace} scroll={scroll} shallow={shallow}>
          <SizableTextFrame {...rest} ref={ref} />
        </TextLink>
      )
    }

    return <SizableTextFrame {...rest} ref={ref} />
  }
)
