import { gql } from '@apollo/client'

export const UserOTP = gql`
  mutation SessionPut($phone: String!, $otp: String!) {
    sessionPut(data: { phone: $phone, otp: $otp }) {
      status
      product
      status_data
      screen
      token
      url
    }
  }
`
