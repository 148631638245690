import { gql } from "@apollo/client";
import { BillingFragment } from "./billing";

export const BillingWithExtensionPlansFragment = gql`
  fragment BillingWithExtensionPlansFragment on Billing {
    ...BillingFragment
    extensionPlans {
      id
      percentage
      price
      days
    }
  }
  ${BillingFragment}
`