import { gql } from '@apollo/client'

export const UserEmailVerify = gql`
  mutation DocumentVerifyGmail($serverToken: String!) {
    documentVerifyGmail(data: { serverToken: $serverToken}) {
      status
      product
      status_data
      screen
    }
  }
`
