import { gql } from '@apollo/client'

export const getState = gql`
  query AddressesNew {
    shopping {
      addressesNew {
        id
        name
      }
    }
    posts {
      recent(count: 5) {
        name
        title
      }
    }
  }
`
