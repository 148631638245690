export const radius = {
  0: 0,
  true: 5,
  1: 5,
  2: 10,
  3: 15,
  4: 20,
  5: 20,
  6: 24,
  7: 26,
}
