import { Cookie, Store } from 'tough-cookie'
import { cookieStorage } from './cookieStorage'

interface Callback<T> {
  (error: Error, result?: never): void
  (error: null, result: T): void
}

interface PromiseCallback<T> {
  promise: Promise<T>
  callback: Callback<T>
  resolve: (value: T) => Promise<T>
  reject: (error: Error) => Promise<T>
}

function createPromiseCallback<T>(cb?: Callback<T>): PromiseCallback<T> {
  let callback: Callback<T>
  let resolve: (result: T) => void
  let reject: (error: Error) => void

  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve
    reject = _reject
  })

  if (typeof cb === 'function') {
    callback = (err, result) => {
      try {
        if (err) cb(err)
        // If `err` is null, we know `result` must be `T`
        // The assertion isn't *strictly* correct, as `T` could be nullish, but, ehh, good enough...
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        else cb(null, result!)
      } catch (e) {
        reject(e instanceof Error ? e : new Error())
      }
    }
  } else {
    callback = (err, result) => {
      try {
        // If `err` is null, we know `result` must be `T`
        // The assertion isn't *strictly* correct, as `T` could be nullish, but, ehh, good enough...
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        err ? reject(err) : resolve(result!)
      } catch (e) {
        reject(e instanceof Error ? e : new Error())
      }
    }
  }

  return {
    promise,
    callback,
    resolve: (value: T) => {
      callback(null, value)
      return promise
    },
    reject: (error: Error) => {
      callback(error)
      return promise
    },
  }
}

export class MmkvCookieStore extends Store {
  override synchronous: boolean

  constructor() {
    super()
    this.synchronous = true
  }

  override findCookie(
    domain: string | null,
    path: string | null,
    key: string | undefined,
  ): Promise<Cookie | null>
  override findCookie(
    domain: string | null,
    path: string | null,
    key: string | undefined,
    callback: Callback<Cookie | null>,
  ): void
  override findCookie(
    domain: string | null,
    path: string | null,
    key: string | undefined,
    callback?: Callback<Cookie | null>,
  ): unknown {
    const promiseCallback = createPromiseCallback(callback)
    if (domain == null || path == null || key == null) {
      return promiseCallback.resolve(null)
    }
    const cookie = cookieStorage.get(key)
    return promiseCallback.resolve(cookie ?? null)
  }

  override findCookies(
    domain: string,
    path: string,
    allowSpecialUseDomain?: boolean
  ): Promise<Cookie[]>
  override findCookies(
    domain: string,
    path: string,
    allowSpecialUseDomain?: boolean,
    callback?: Callback<Cookie[]>
  ): void
  override findCookies(
    domain: string,
    path: string,
    allowSpecialUseDomain: boolean | Callback<Cookie[]> = false,
    callback?: Callback<Cookie[]>,
  ): unknown {
    const promiseCallback = createPromiseCallback<Cookie[]>(callback)
    const cb = promiseCallback.callback

    const cookies: Cookie[] = []

    const keys = cookieStorage.getAllKeys()
    for (const key of keys) {
      const cookie = cookieStorage.get(key)
      if (cookie !== undefined && cookie !== null) {
        cookies.push(cookie)
      }
    }
    cb(null, cookies)
    return promiseCallback.promise
  }

  override putCookie(cookie: Cookie): Promise<void>
  override putCookie(cookie: Cookie, callback: Callback<void>): void
  override putCookie(cookie: Cookie, callback?: Callback<void>): unknown {
    const promiseCallback = createPromiseCallback<void>(callback)
    const cb = promiseCallback.callback

    const { domain, path, key } = cookie
    if (domain == null || path == null || key == null) {
      cb(null, undefined)
      return promiseCallback.promise
    }
    cookieStorage.set(cookie)

    cb(null, undefined)

    return promiseCallback.promise
  }

  override updateCookie(oldCookie: Cookie, newCookie: Cookie): Promise<void>
  override updateCookie(oldCookie: Cookie, newCookie: Cookie, callback: Callback<void>): void
  override updateCookie(_oldCookie: Cookie, newCookie: Cookie, callback?: Callback<void>): unknown {
    // this seems wrong but it stops typescript from complaining and all the test pass...
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback = callback ?? function () { }

    // updateCookie() may avoid updating cookies that are identical.  For example,
    // lastAccessed may not be important to some stores and an equality
    // comparison could exclude that field.
    return this.putCookie(newCookie, callback)
  }

  override removeCookie(domain: string, path: string, key: string): Promise<void>
  override removeCookie(domain: string, path: string, key: string, callback: Callback<void>): void
  override removeCookie(
    domain: string,
    path: string,
    key: string,
    callback?: Callback<void>,
  ): unknown {
    const promiseCallback = createPromiseCallback<void>(callback)
    const cb = promiseCallback.callback
    cookieStorage.delete(key)
    cb(null, undefined)
    return promiseCallback.promise
  }

  override removeCookies(domain: string, path: string): Promise<void>
  override removeCookies(domain: string, path: string, callback: Callback<void>): void
  override removeCookies(domain: string, path: string, callback?: Callback<void>): unknown {
    const promiseCallback = createPromiseCallback<void>(callback)
    const cb = promiseCallback.callback

    cookieStorage.deleteAll()

    cb(null)
    return promiseCallback.promise
  }

  override getAllCookies(): Promise<Cookie[]>
  override getAllCookies(callback: Callback<Cookie[]>): void
  override getAllCookies(callback?: Callback<Cookie[]>): unknown {
    const promiseCallback = createPromiseCallback<Cookie[]>(callback)
    const cb = promiseCallback.callback

    const cookies: Cookie[] = []

    const keys = cookieStorage.getAllKeys()
    for (const key of keys) {
      const cookie = cookieStorage.get(key)
      if (cookie !== undefined && cookie !== null) {
        cookies.push(cookie)
      }
    }
    cb(null, cookies)
    return promiseCallback.promise
  }
}
