import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M8.01 4.542c2.24-2.24 6.02-2.07 7.58.725l.865 1.551c1.019 1.825.585 4.128-.907 5.637a.837.837 0 0 0-.138.448c-.017.342.105 1.132 1.329 2.357s2.014 1.346 2.356 1.329a.846.846 0 0 0 .449-.138c1.509-1.491 3.812-1.925 5.637-.907l1.551.865c2.795 1.56 2.965 5.34.725 7.58-1.198 1.198-2.791 2.263-4.664 2.334-2.775.105-7.382-.611-11.944-5.174S5.57 11.98 5.675 9.205c.071-1.872 1.136-3.465 2.334-4.664zm5.834 1.7c-.799-1.431-2.945-1.76-4.419-.285-1.034 1.034-1.706 2.175-1.75 3.325-.088 2.314.484 6.348 4.589 10.454s8.14 4.677 10.454 4.589c1.15-.044 2.291-.716 3.325-1.749 1.474-1.474 1.146-3.621-.285-4.419l-1.551-.865c-.965-.538-2.319-.355-3.271.596-.093.093-.688.648-1.743.699-1.08.053-2.388-.433-3.868-1.913s-1.966-2.788-1.913-3.869c.052-1.055.607-1.649.699-1.742.951-.951 1.135-2.306.596-3.271l-.865-1.551z" />
    </Svg>
  )
}

Icon.displayName = 'Phone'

export const Phone = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
