import type { IconProps } from '@tamagui/helpers-icon'
import { themed } from '@tamagui/helpers-icon'
import React, { memo } from 'react'
import { Path, Svg } from 'react-native-svg'

const Icon = (props: IconProps) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill={color ?? undefined} {...otherProps}>
      <Path d="M27.667 9.333a1 1 0 0 1-1 1H5.334a1 1 0 0 1 0-2h21.333a1 1 0 0 1 1 1zm0 6.667a1 1 0 0 1-1 1H5.334a1 1 0 0 1 0-2h21.333a1 1 0 0 1 1 1zm0 6.667a1 1 0 0 1-1 1H5.334a1 1 0 0 1 0-2h21.333a1 1 0 0 1 1 1z" />
    </Svg>
  )
}

Icon.displayName = 'Hamburger'

export const Hamburger = memo<IconProps>(
  themed(Icon, {
    defaultStrokeWidth: 0,
  })
)
