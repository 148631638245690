import { GetProps, styled } from '@tamagui/web'
import { Paragraph } from './Paragraph'

export const Heading = styled(Paragraph, {
  tag: 'span',
  name: 'Heading',
  accessibilityRole: 'header',
  variants: {
    unstyled: {
      false: {
        size: '$true',
        margin: 0,
        weight: '$3',
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

export type HeadingProps = GetProps<typeof Heading>

export const H1 = styled(Heading, {
  name: 'H1',
  tag: 'h1',
  variants: {
    unstyled: {
      false: {
        size: '$12',
        $gtXs: {
          size: '$15',
        },
        $gtMd: {
          size: '$17',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

export const H2 = styled(Heading, {
  name: 'H2',
  tag: 'h2',
  variants: {
    unstyled: {
      false: {
        size: '$11',
        $gtXs: {
          size: '$14',
        },
        $gtMd: {
          size: '$16',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

export const H3 = styled(Heading, {
  name: 'H3',
  tag: 'h3',
  variants: {
    unstyled: {
      false: {
        size: '$10',
        $gtXs: {
          size: '$12',
        },
        $gtMd: {
          size: '$15',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})

export const H4 = styled(Heading, {
  name: 'H4',
  tag: 'h4',
  variants: {
    unstyled: {
      false: {
        size: '$9',
        $gtXs: {
          size: '$11',
        },
        $gtMd: {
          size: '$14',
        },
      },
    },
  } as const,
  defaultVariants: {
    unstyled: false,
  },
})
