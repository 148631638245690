import { gql } from '@apollo/client'

export const getProcessingfee = gql`
  query PFquery {
    getProcessingfee {
      ProcessingFee {
        cashbackAmount
        id
        popular
        price
      }
    }
  }
`
