import { gql } from '@apollo/client'

export const allCoupons = gql`
  mutation ApplyCoupon($data: CouponInput) {
    applyCoupon(data: $data) {
      cart {
        address {
          id
          firstname
          lastname
          address1
          address2
          city
          zipcode
          phone
          alternativePhone
          company
          state_id
          state
          country_id
          user_id
          label
          preferences
        }
        discount
        finalPrice
        id
        state
        totalPrice
        lineItems {
          id
          quantity
          product {
            id
            slug
            name
            vendor
            price
            originalPrice
            image
            imageSrcSet {
              width
              url
              height
            }
            options
          }
          price
          total
          productId
          name
        }
        promotions {
          id
          name
          coupon
          promoDiscount
          removable
        }
      }
      coupon
      message
    }
  }
`

export const cancleCoupon = gql`
  mutation RemoveCoupon {
    removeCoupon {
      cart {
        address {
          id
          firstname
          lastname
          address1
          address2
          city
          zipcode
          phone
          alternativePhone
          company
          state_id
          state
          country_id
          user_id
          label
          preferences
        }
        id
        state
        totalPrice
        discount
        finalPrice
        lineItems {
          id
          quantity
          product {
            id
            image
            name
            price
          }
          price
          total
          productId
          name
        }
      }
    }
  }
`
